import gql from 'graphql-tag';

const CREATE_WORKER = gql`
  mutation (
    $entity: WorkerCreateInput!
  ) {
    createWorker (
      entity: $entity,
    )
  }
`;

export default CREATE_WORKER;
