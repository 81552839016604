import gql from 'graphql-tag';

export const GET_PAYMENT_METHODS = gql`
  query getPaymentMethods {
    paymentMethods {
      default
      cardType
      maskedNumber
      expirationDate
    }
  }
`;
