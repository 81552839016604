import React, { useState } from 'react';
import { Form, Message, Button, Modal } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';

import CREATE_DEVICE from '../graphql/mutations/createDevice';

const CreateDeviceModal = ({ isOpen, setOpen, deviceId }) => {
  const [mutationLoading, setMutationLoading] = useState(false);
  const [mutationError, setMutationError] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
  });

  const close = () => !mutationLoading ? setOpen(false) : null;

  const [createDeviceMutation] = useMutation(CREATE_DEVICE, {
    variables: {
      entity: formData,
    },
    refetchQueries: ['getDevices'],
  });

  async function handleClick() {
    try {
      setMutationLoading(true);

      await createDeviceMutation();

      close();
    } catch (e) {
      setMutationLoading(false);
      if (e.networkError) {
        setMutationError('Network error. Check your connection.');
      } else if (e.graphQLErrors) {
        setMutationError(e.graphQLErrors[0].message);
      } else {
        setMutationError(e.message);
      }
    }
  }

  function handleChange(e, { name, value }) {
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const errorMessage = mutationError ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ mutationError }</p>
    </Message>
  ) : null;

  return (
    <Modal
      size='tiny'
      dimmer='blurring'
      open={isOpen}
      onClose={close}
    >
      <Modal.Header>Create new device</Modal.Header>
      <Modal.Content>
        { errorMessage }

        <Form
          size='small'
          autoComplete='off'
          id='modal-form'
        >
          <Form.Input
            fluid
            name='name'
            label='Name'
            value={formData.name}
            onChange={handleChange}
          />
        </Form>

      </Modal.Content>

      <Modal.Actions>
        <Button
          content='Cancel'
          onClick={close}
          disabled={mutationLoading}
        />
        <Button
          form='modal-form'
          color='teal'
          content='Confirm'
          disabled={mutationLoading}
          loading={mutationLoading}
          onClick={handleClick}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default CreateDeviceModal;
