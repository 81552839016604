import gql from 'graphql-tag';

const UPDATE_DEVICE = gql`
  mutation (
    $entity: DeviceUpdateInput!
  ) {
    updateDevice (
      entity: $entity,
    )
  }
`;

export default UPDATE_DEVICE;
