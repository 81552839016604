import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { EntriesTable } from '../../../components/EntriesTable';
import GET_ENTRIES from '../../../graphql/queries/getEntries';
import GET_PROJECTS from '../../../graphql/queries/getProjects';
import GET_WORKS from '../../../graphql/queries/getWorks';
import GET_WORKERS from '../../../graphql/queries/getWorkers';
import { useManipulators } from '../../../hooks/useManipulators';

export const ActivityTab = ({ device }) => {
  const { data: projectsData = {}, loading: projectsLoading } = useQuery(GET_PROJECTS);
  const { data: worksData = {}, loading: worksLoading } = useQuery(GET_WORKS);
  const { data: workersData = {}, loading: workersLoading } = useQuery(GET_WORKERS);

  const { filters, orderBy, manipulator } = useManipulators({
    topBorder: true,
    filters: [
      {
        name: 'project',
        type: 'select',
        options: projectsData.projects,
        loading: projectsLoading,
      },
      {
        name: 'worker',
        type: 'select',
        options: workersData.workers,
        loading: workersLoading,
      },
      {
        name: 'work',
        type: 'select',
        options: worksData.works,
        loading: worksLoading,
      },
      {
        name: 'device',
        type: 'select',
        default: device.id,
        hide: true,
      },
    ],
    orderBy: {
      options: [
        { text: 'Start time', value: 'start' },
        { text: 'End time', value: 'end' },
        { text: 'Project', value: 'project'},
        { text: 'Work', value: 'work'},
        { text: 'Devices', value: 'device'},
      ],
    },
  });

  const { data: entriesData = {}, loading: entriesLoading } = useQuery(GET_ENTRIES, {
    fetchPolicy: 'cache-and-network',
    variables: { filters, orderBy },
  });

  return (
    <>
      { manipulator }
      <EntriesTable
        hideDevices
        entriesLoading={entriesLoading}
        entriesData={entriesData}
      />
    </>
  );
};
