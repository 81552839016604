import React, { useState } from 'react';
import { Form, Button, Message } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import RESET_PASSWORD from '../../graphql/mutations/resetPassword';
import { parseErrors } from '../../libs/errors';
import { AccountLayout } from '../../layouts/AccountLayout';

const ResetPassword = ({ match }) => {
  const { resetPasswordCode } = match.params;

  const [formData, setFormData] = useState({
    password: '',
  });
  const [mutationErrors, setMutationErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [resetPasswordMutation] = useMutation(RESET_PASSWORD, {
    variables: {
      data: {...formData, resetPasswordCode },
    },
  });

  async function resetPassword() {
    try {
      await resetPasswordMutation();

      setSuccess(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      const errors = parseErrors(e);
      setMutationErrors(errors);
    }
  }

  function handleChange(e, { name, value }) {
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const errorMessage = mutationErrors && mutationErrors.general ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ mutationErrors.general }</p>
    </Message>
  ) : null;

  const successMessage = success ? (
    <Message positive>
      <Message.Header>Success!</Message.Header>
      Your password has been changed. <Link to='/signin'>Sign in</Link>
    </Message>
  ) : null;

  return (
    <AccountLayout
      headerText='Set up new password'
    >
      { errorMessage }
      { successMessage }
      {
        !success ? (
          <Form
            size='small'
            noValidate
          >
            <Form.Input
              fluid
              name='password'
              type='password'
              icon='lock'
              iconPosition='left'
              placeholder='New password'
              value={formData.password}
              error={mutationErrors ? mutationErrors.password : null}
              onChange={handleChange}
            />

            <br />

            <Button
              fluid
              content='Save new password'
              color='teal'
              size='small'
              onClick={resetPassword}
              loading={loading}
              disabled={loading}
            />
          </Form>
        ) : null
      }
    </AccountLayout>
  );
};

export default withRouter(ResetPassword);
