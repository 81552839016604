import React from 'react'
import { Image, Header, Segment } from 'semantic-ui-react';

import DemoImage from '../../../assets/landing.png';

export const PresentationSegment = () => {
  return (
    <Segment
      basic
      vertical
      textAlign='center'
      style={{ padding: '8em 0em' }}
    >
      <Header as='h3' style={{ fontSize: '2em' }}>
        Effortless project progress monitoring and work budgeting.
      </Header>
      <p style={{ fontSize: '1.33em' }}>
        Continuous progress visibility enables your organization to predict and adapt to upcoming workloads.
      </p>
      <Image
        src={DemoImage}
        size='massive'
        style={styles.image}
      />
    </Segment>
  );
}

const styles = {
  image: {
    margin: 'auto',
    border: '1px solid rgba(34,36,38,.15)',
    borderRadius: '6px',
    marginTop: '50px',
  },
};
