import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Table, Message, Loader, Icon, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { AppContext } from '../../../contexts/AppContext';
import GET_PROJECTS from '../../../graphql/queries/getProjects';
import GET_WORKS from '../../../graphql/queries/getWorks';
import { useManipulators } from '../../../hooks/useManipulators';
import { assignedProjectsDropdown } from '../../../components/AssignedProjectsDropdown';

export const WorksTable = ({ archived }) => {
  const { mobile } = useContext(AppContext);
  const [firstFetch, setFirstFetch] = useState(true);
  const [cachedData, setCachedData] = useState({ works: [] });

  const { data: projectsData = {}, loading: projectsLoading } = useQuery(GET_PROJECTS);

  const { filters, orderBy, manipulator } = useManipulators({
    filters: [
      { name: 'name', type: 'input' },
      { name: 'code', type: 'input' },
      {
        name: 'projects',
        type: 'select',
        multiple: true,
        options: projectsData.projects,
      },
    ],
    orderBy: {
      options: [
        { text: 'Created date', value: 'createdAt' },
        { text: 'Last updated date', value: 'updatedAt' },
        { text: 'Name', value: 'name' },
      ],
    },
  });

  const { data: worksData = {}, loading: worksLoading } = useQuery(GET_WORKS, {
    fetchPolicy: 'cache-and-network',
    variables: archived ? { filters: { archived: true } } : { filters, orderBy },
  });

  const loading = projectsLoading || worksLoading;

  useEffect(() => {
    if (Object.keys(worksData).length) {
      setCachedData(worksData);
    }
  }, [worksData]);

  useEffect(() => {
    if (!loading && firstFetch) {
      setFirstFetch(false);
    }
  }, [loading, firstFetch]);

  if (loading && firstFetch) {
    return (
      <Segment basic padded='very'>
        <Loader active />
      </Segment>
    );
  }

  const noResults = !cachedData.works.length ? (
    <Message>
      <Message.Header>No results found</Message.Header>
      <p>{
        archived
          ? 'No archived works.'
          : 'Create new items or specify different filters.'
      }</p>
    </Message>
  ) : null;

  return (
    <React.Fragment>
      { !archived ? manipulator : null }
      <Table unstackable basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell><Icon name='tasks' /> Work</Table.HeaderCell>
            <Table.HeaderCell width={5} textAlign='right'><Icon name='clipboard outline' /> In projects</Table.HeaderCell>
            {
              !mobile ? (
                <Table.HeaderCell width={4} />
              ) : null
            }
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            cachedData.works.map(({ id, name, code, createdAt, projects }) => (
              <Table.Row key={id} >
                <Table.Cell>
                  <Link to={`/works/${id}`}>{ name }</Link>
                  <p>{ code }</p>
                </Table.Cell>
                <Table.Cell textAlign='right'>{ assignedProjectsDropdown(projects) }</Table.Cell>
                {
                  !mobile ? (
                    <Table.Cell>
                      <Icon name='clock outline' /> { moment(Number(createdAt)).calendar() }
                    </Table.Cell>
                  ) : null
                }
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
      { noResults }
    </React.Fragment>
  );
};
