import React, { useState } from 'react';
import { Tab, Message } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { useQuery } from '@apollo/react-hooks';

import { ArchiveButton } from '../../components/ArchiveButton';
import { GetReportButton } from '../../components/GetReportButton';
import EditWorkModal from '../../modals/editWorkModal';
import CommonLayout from '../../layouts/CommonLayout';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import { GET_WORK } from '../../graphql/queries/getWork';
import { ARCHIVE_WORK } from '../../graphql/mutations/archiveWork';
import { OverviewTab } from './components/OverviewTab';
import { ActivityTab } from './components/ActivityTab';

const Work = ({ match }) => {
  const { workId } = match.params;

  const [ isEditWorkModalOpen, setEditWorkModalOpen ] = useState(false);

  const { data = {}, loading } = useQuery(GET_WORK, {
    variables: {
      workId: Number(workId),
    },
  });

  if (loading) {
    return <LoadingPlaceholder />;
  }

  const panes = [
    { menuItem: 'Overview', render: () => <OverviewTab work={data.work} /> },
    { menuItem: 'Activity', render: () => <ActivityTab work={data.work} /> },
  ];

  const archivedMessage = data.work.archived ? (
    <Message warning>
      <Message.Header>Work is archived.</Message.Header>
      <Message.Content>Archived works will not appear in projects and devices.</Message.Content>
    </Message>
  ) : null;

  return (
    <CommonLayout
      header={{
        text: data.work.name,
        subheader: data.work.code,
        buttons: [{
          component: ArchiveButton,
          mutation: ARCHIVE_WORK,
          entity: data.work,
          refetchQueries: ['getWork'],
        }, {
          component: GetReportButton,
          reportOn: { work: data.work.id },
        }, {
          text: 'Edit work',
          color: 'teal',
          onClick: () => setEditWorkModalOpen(true),
        }],
      }}

      surface={
        <React.Fragment>
          {
            isEditWorkModalOpen ? (
              <EditWorkModal
                isOpen={isEditWorkModalOpen}
                setOpen={setEditWorkModalOpen}
                workId={workId}
                work={data.work}
              />
            ) : null
          }
          { archivedMessage }

          <Tab
            menu={{ secondary: true, pointing: true, style: { borderBottomWidth: '1px', margin: '20px 0 0' } }}
            panes={panes}
          />
        </React.Fragment>
      }
    />
  );
};

export default withRouter(Work);
