import React, { useContext, useEffect, useState } from 'react';
import { Accordion, Form, Icon, Segment, Divider } from 'semantic-ui-react';

import { AppContext } from '../contexts/AppContext';

function itemsToDropdownOptions(items) {
  return items.map(item => ({
    key: item.id,
    text: item.name,
    value: item.id,
  }));
}

function itemsToOrderByDropdownOptions(items) {
  return items.filter((item) => !item.hide).map((item) => ({
    text: item.text,
    value: item.value,
    key: item.value,
  }));
}

function getDefaultFilters(categories) {
  return categories.reduce((filters, category) => {
    switch(category.type) {
      case 'input':
        filters[category.name] = category.default || '';
        break;
      case 'select':
        if (category.default) {
          filters[category.name] = category.default;
        } else {
          filters[category.name] = category.multiple ? [] : null;
        }
        break;
      default:
        throw new Error('Unknown filter category type!');
    }

    return filters;
  }, {});
}

export const useManipulators = ({ topBorder = false, filters, orderBy }) => {
  const { mobile } = useContext(AppContext);
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);

  const [initDone, setInitDone] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [orderByData, setOrderByData] = useState(orderBy.options[0].value);
  const [reverse, setReverse] = useState(orderBy.reverse);

  useEffect(() => {
    const defaultFilters = getDefaultFilters(filters);
    //const filtersUpdated = JSON.stringify(filterData) !== JSON.stringify(defaultFilters);

    //if (!initDone || filtersUpdated) {
    if (!initDone) {
      setFilterData(defaultFilters);
      setInitDone(true);
    }
  }, [filters, initDone, filterData]);

  function handleFiltersChange(e, data) {
    // This check is needed when clearing single-selection dropdown.
    // Filters expect a number, but clear event sets it to empty string.
    const value = !data.multiple && !data.value ? null : data.value;

    setFilterData({...filterData, [data.name]: value});
  }

  function handleOrderChange(e, data) {
    setOrderByData(data.value);
  }

  if (!initDone) {
    return {};
  }

  const formInputs = filters.filter((filter) => !filter.hide).map(filter => {
    switch(filter.type) {
      case 'input':
        return (
          <Form.Input
            fluid
            key={filter.name}
            name={filter.name}
            value={filterData[filter.name]}
            placeholder={`Filter by ${filter.name}`}
            onChange={handleFiltersChange}
          />
        );
      case 'select':
        return (
          <Form.Select
            fluid
            key={filter.name}
            name={filter.name}
            options={itemsToDropdownOptions(filter.options ? filter.options : [])}
            value={filterData[filter.name]}
            placeholder={`All ${filter.name}`}
            multiple={filter.multiple}
            clearable
            search
            onChange={handleFiltersChange}
          />
        );
      default:
        throw new Error('Unknown filter type!');
    }
  });

  const order = reverse ? 'Ascending' : 'Descending';

  const manipulatorForm = (
    <Form autoComplete='off'>
      <Form.Group
        widths='equal'
        style={styles.formGroup}
      >
        { formInputs }
        <Form.Select
          fluid
          options={itemsToOrderByDropdownOptions(orderBy.options)}
          value={orderByData}
          onChange={handleOrderChange}
          selection
        />
        <Form.Button
          icon
          basic
          fluid={mobile}
          width={1}
          onClick={() => setReverse(!reverse)}
        >
          <Icon
            name={ reverse ? 'sort content ascending' : 'sort content descending' }
            style={{...(mobile ? { paddingRight: '30px' } : null)}}
          />
          { mobile ? order : null }
        </Form.Button>
      </Form.Group>
    </Form>
  );

  const manipulator = mobile ? (
    <React.Fragment>
      <Segment
        basic
        style={{
          ...styles.segment,
          ...(mobile ? { padding: 0 } : null),
        }}
      >
        <Accordion fluid>
          <Accordion.Title
            active={mobileAccordionOpen}
            style={styles.accordionTitle}
            onClick={() => setMobileAccordionOpen(!mobileAccordionOpen)}
          >
            <Icon name={mobileAccordionOpen ? 'close' : 'filter'}/>
            <b>{ mobileAccordionOpen ? 'Close filters' : 'Open filters' }</b>
          </Accordion.Title>
          <Accordion.Content
            active={mobileAccordionOpen}
            style={styles.accordionContent}
          >
            { manipulatorForm }
          </Accordion.Content>
        </Accordion>
      </Segment>
      <Divider style={styles.divider} />
    </React.Fragment>
  ) : (
    <React.Fragment>
      {
        topBorder ? (
          <Divider style={styles.divider} />
        ) : null
      }
      <Segment
        basic
        style={styles.segment}
      >
        { manipulatorForm }
      </Segment>
      <Divider style={styles.divider} />
    </React.Fragment>
  );

  return {
    filters: filterData,
    orderBy: {
      by: orderByData,
      reverse,
    },
    manipulator,
  };
}

const styles = {
  segment: {
    margin: 0,
    backgroundColor: '#f9f9f9',
  },
  formGroup: {
    marginBottom: 0,
  },
  divider: {
    margin: 0,
  },
  accordionTitle: {
    padding: '15px',
    backgroundColor: '#ededed',
  },
  accordionContent: {
    padding: '15px',
  },
}
