import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Message, Button, Form, Header } from 'semantic-ui-react';
import { useHistory, Link } from 'react-router-dom';

import { GET_PAYMENT_METHODS } from '../graphql/queries/getPaymentMethods';

function mapPaymentMethodsToDropdownOptions(paymentMethods = []) {
  const options = paymentMethods.map((paymentMethod, i) => ({
    text: `${paymentMethod.maskedNumber}, ${paymentMethod.expirationDate}`,
    value: i.toString(),
    icon: 'credit card outline',
  }));

  return options.concat([{
    text: 'Update payment method',
    value: 'new',
    icon: 'pencil',
  }]);
}

export const PaymentMethodDropdown = ({ setLoading, setError }) => {
  const history = useHistory();
  const { data, loading, error } = useQuery(GET_PAYMENT_METHODS, {
    fetchPolicy: 'network-only',
  });

  setLoading(loading);
  setError(error);

  if(!loading && error) {
    return (
      <Message error>
        <Message.Header>Error has occured</Message.Header>
        <p>Error loading payment methods from remote service. Try again later.</p>
      </Message>
    );
  }

  if (!loading && (!data || !data.paymentMethods || !data.paymentMethods.length)) {
    return (
      <>
        <Header
          as='h4'
          textAlign='center'
          style={{ padding: '30px 0' }}
        >
          <Header.Content>
            <p>You don't have a payment method set up</p>
            <Button
              as={Link}
              to='/payments'
              color='teal'
              content='Add payment method'
            />
          </Header.Content>
        </Header>
      </>
    );
  }

  function handleChange(e, { name, value }) {
    if (value === 'new') {
      history.push('/payments');
    }
  }

  const dropdownOptions = !loading
    ? mapPaymentMethodsToDropdownOptions(data.paymentMethods)
    : [];

  return (
    <Form.Dropdown
      fluid
      selection
      name='paymentMethodToken'
      label='Payment method'
      placeholder='Choose payment method'
      options={dropdownOptions}
      loading={loading}
      disabled={loading}
      value={'0'}
      onChange={handleChange}
    />
  );
}
