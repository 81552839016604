import React, { useState } from 'react';
import { Tab } from 'semantic-ui-react';
import { useQuery } from '@apollo/react-hooks';

import { GET_QUOTA } from '../../graphql/queries/getQuota';
import CommonLayout from '../../layouts/CommonLayout';
import CreateWorkertModal from '../../modals/createWorkerModal';
import { WorkersTable } from './components/WorkersTable';

const Workers = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const { data = {}, loading } = useQuery(GET_QUOTA, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        quotaType: 'FreeWorkers',
        excludeArchived: true,
      },
    },
  });

  const panes = [
    { menuItem: 'Active', render: () => <WorkersTable /> },
    { menuItem: 'Archived', render: () => <WorkersTable archived /> },
  ];

  const eligibleForFreeWorker = data.quota ? data.quota.quota - data.quota.used > 0 : false;

  return (
    <CommonLayout
      header={{
        text: 'Workers',
        buttons: [{
          text: eligibleForFreeWorker ? 'Create worker' : 'Purchase worker',
          color: 'teal',
          loading,
          disabled: loading,
          onClick: () => setModalOpen(true),
        }]
      }}

      surface={
        <React.Fragment>
          {
            isModalOpen ? (
              <CreateWorkertModal
                isOpen={isModalOpen}
                setOpen={setModalOpen}
                eligibleForFreeWorker={eligibleForFreeWorker}
                quota={data.quota}
              />
            ) : null
          }
          <Tab
            menu={{ secondary: true, pointing: true, style: { borderBottomWidth: '1px', margin: '20px 0 0' } }}
            panes={panes}
          />
        </React.Fragment>
      }
    />
  );
};

export default Workers;
