import gql from 'graphql-tag';

const GET_REPORTING_DROPDOWN_DATA = gql`
  query {
    projects {
      id
      name
      code
      start
      end
      status
      createdAt
    }

    devices {
      id
      name
    }

    workers {
      id
      name
      createdAt
    }

    works {
      id
      name
      createdAt
    }
  }
`;

export default GET_REPORTING_DROPDOWN_DATA;
