import XLSX from 'xlsx';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Form, Message } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';

import GET_REPORTING_DROPDOWN_DATA from '../../../graphql/queries/getReportingDropdownData';
import DOWNLOAD_REPORT from '../../../graphql/mutations/downloadReport';
import GET_ENTRIES from '../../../graphql/queries/getEntries';
import { useManipulators } from '../../../hooks/useManipulators';
import { EntriesTable } from '../../../components/EntriesTable';

function mapEntityToDropdownOptions(entities = [], suffix) {
  const defaultOption = {
    text: `All ${suffix}`,
    value: 0,
  };

  return [defaultOption].concat(entities.map(entity => ({
    key: entity.id,
    text: entity.name,
    value: entity.id,
  })));
}

function firstDateOfMonth() {
  return moment().startOf('month').format('DD.MM.YYYY');
}

function lastDateOfMonth() {
  return moment().endOf('month').format('DD.MM.YYYY');
}

export const GeneralReporting = () => {
  const { reportOn } = useLocation();

  const [mutationError, setMutationError] = useState(false);
  const [mutationLoading, setMutationLoading] = useState(false);
  const [formData, setFormData] = useState({
    start: firstDateOfMonth(),
    end: lastDateOfMonth(),
    project: reportOn && reportOn.project ? reportOn.project : 0,
    worker: reportOn && reportOn.worker ? reportOn.worker : 0,
    work: reportOn && reportOn.work ? reportOn.work : 0,
    device: reportOn && reportOn.device ? reportOn.device : 0,
  });

  const { data = {}, loading } = useQuery(GET_REPORTING_DROPDOWN_DATA);

  const [downloadReportMutation] = useMutation(DOWNLOAD_REPORT, {
    variables: {
      data: formData,
    },
  });

  async function downloadReport() {
    setMutationLoading(true);

    try {
      const { data } = await downloadReportMutation();

      const worksheet = XLSX.utils.json_to_sheet(JSON.parse(data.downloadReport));
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, worksheet, 'Report sheet');

      XLSX.writeFile(wb, `REPORT_${moment().format('DDMMYYYY-HHmm')}.xlsx`)

      setMutationError(null);
      setMutationLoading(false);
    } catch (e) {
      setMutationLoading(false);

      if (e.networkError) {
        setMutationError('Network error. Check your connection.');
      } else if (e.graphQLErrors) {
        setMutationError(e.graphQLErrors[0].message);
      } else {
        setMutationError(e.message);
      }
    }
  }

  const { data: entriesData = {}, loading: entriesLoading } = useQuery(GET_ENTRIES, {
    fetchPolicy: 'cache-and-network',
    variables: { 
      filters: formData,
      orderBy: { by: 'start' }
    },
  });

  function handleChange(e, { name, value }) {
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const errorMessage = mutationError ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ mutationError }</p>
    </Message>
  ) : null;

  return (
    <React.Fragment>
      { errorMessage }
      <Form
        loading={loading}
        size='small'
        autoComplete='off'
        id='modal-form'
      >
        <Form.Group widths='equal'>
          <DateInput
            name='start'
            label='Start date'
            value={formData.start}
            animation='none'
            dateFormat='DD.MM.YYYY'
            closable={true}
            popupPosition='bottom right'
            preserveViewMode={false}
            onChange={handleChange}
          />

          <DateInput
            name='end'
            label='End date'
            value={formData.end}
            animation='none'
            dateFormat='DD.MM.YYYY'
            minDate={formData.start}
            closable={true}
            popupPosition='bottom right'
            preserveViewMode={false}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group widths='equal'>
          <Form.Dropdown
            search
            selection
            name='project'
            label='Project'
            value={formData.project}
            options={mapEntityToDropdownOptions(data.projects, 'projects')}
            onChange={handleChange}
          />

          <Form.Dropdown
            search
            selection
            name='worker'
            label='Worker'
            value={formData.worker}
            options={mapEntityToDropdownOptions(data.workers, 'workers')}
            onChange={handleChange}
          />

          <Form.Dropdown
            search
            selection
            name='work'
            label='Work'
            value={formData.work}
            options={mapEntityToDropdownOptions(data.works, 'works')}
            onChange={handleChange}
          />

          <Form.Dropdown
            search
            selection
            name='device'
            label='Device'
            value={formData.device}
            options={mapEntityToDropdownOptions(data.devices, 'devices')}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Button
          color='teal'
          icon='save'
          content='Download'
          loading={mutationLoading}
          disabled={mutationLoading}
          onClick={downloadReport}
        />
      </Form>

      <EntriesTable
        entriesData={entriesData}
        entriesLoading={entriesLoading}
      />
    </React.Fragment>
  );
};
