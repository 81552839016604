import moment from 'moment';
import React, { useState } from 'react';
import { Dimmer, Loader, List, Message, Button, Form } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/react-hooks';

import SIGN_IN from '../../graphql/mutations/signIn';
import { parseErrors } from '../../libs/errors';
import { AccountLayout } from '../../layouts/AccountLayout';

function handleToken(history, data) {
  const token = data.signIn.token;

  localStorage.setItem('token', token);

  return history.push('/projects');
}

function getHeaderText(organizations) {
  return organizations.length
    ? 'Choose organization'
    : 'Sign in into your account';
}

function getHeaderDescription(organizations) {
  return organizations.length
    ? 'Choose the organization you want to sign in into'
    : null;
}

const SignIn = () => {
  const history = useHistory();
  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [mutationErrors, setMutationErrors] = useState(null);
  const [organizations, setOrganizations] = useState([]);

  const [signInMutation] = useMutation(SIGN_IN, {
    variables: {
      data: formData
    },
  });

  async function signIn() {
    try {
      setLoading(true);

      const { data } = await signInMutation();

      setLoading(false);
      setMutationErrors(null);

      if (data.signIn.token) {
        return handleToken(history, data);
      }

      if (data.signIn.organizations) {
        setOrganizations(data.signIn.organizations);
      }

    } catch (e) {
      setLoading(false);

      const errors = parseErrors(e);
      setMutationErrors(errors);
    }
  }

  async function signInWithOrganization(organizationId) {
    try {
      setLoading(true);

      const { data } = await client.mutate({
        mutation: SIGN_IN,
        variables: {
          data: {
            ...formData,
            organizationId,
          }
        },
      });

      return handleToken(history, data);
    } catch (e) {
      setLoading(false);

      const errors = parseErrors(e);
      setMutationErrors(errors);
    }
  }

  function handleChange(e, { name, value }) {
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const errorMessage = mutationErrors && mutationErrors.general ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ mutationErrors.general }</p>
    </Message>
  ) : null;

  const organizationList = organizations.length ? organizations.map(({ id, name, joinedAt }) => (
    <List.Item
      key={id}
      style={styles.listItem}
    >
      <List.Content floated='right'>
        <Button
          size='small'
          color='teal'
          content='Launch'
          disabled={loading}
          onClick={() => signInWithOrganization(id)}
        />
      </List.Content>
      <List.Content>{ name }</List.Content>
      <List.Description>{ `Joined on ${moment(Number(joinedAt)).format('DD.MM.YYYY')}` }</List.Description>
    </List.Item>
  )) : null;

  return (
    <AccountLayout
      headerText={getHeaderText(organizations)}
      headerDescription={getHeaderDescription(organizations)}
      showForgotPassword={!organizations.length}
    >
      { errorMessage }
      {
        organizations.length ? (
          <React.Fragment>
            <List
              divided
              size='medium'
              verticalAlign='middle'
            >
              <Dimmer
                inverted
                active={loading}
              >
                <Loader inverted content='Loading' />
              </Dimmer>
              { organizationList }
            </List>
          </React.Fragment>
        ) : (
          <Form
            size='small'
            noValidate
          >
            <Form.Input
              fluid
              name='email'
              type='email'
              icon='at'
              iconPosition='left'
              placeholder='Email'
              value={formData.email}
              error={mutationErrors ? mutationErrors.email : null}
              onChange={handleChange}
            />

            <Form.Input
              fluid
              name='password'
              type='password'
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              value={formData.password}
              error={mutationErrors ? mutationErrors.password : null}
              onChange={handleChange}
            />

            <br />

            <Button
              fluid
              content='Sign In'
              color='teal'
              size='small'
              onClick={signIn}
              loading={loading}
            />
          </Form>
        )
      }
    </AccountLayout>
  );
};

const styles = {
  listItem: {
    padding: '15px 0',
  },
};

export default SignIn;
