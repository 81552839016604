import moment from 'moment';
import React, { useState, useContext } from 'react';
import { Ref } from 'semantic-ui-react';

import CommonLayout from '../../layouts/CommonLayout';
import { GetReportButton } from '../../components/GetReportButton';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import { HorizontalList } from '../../components/HorizontalList';
import EditProjectModal from '../../modals/editProjectModal';
import { RightMenuContext } from '../../contexts/RightMenuContext';
import { EntriesTable } from '../../components/EntriesTable';
import { ApprovalDetails } from './components/ApprovalDetails.jsx';
import { StatusDropdown } from './components/StatusDropdown';
import { CollapsedSidebarElements, ExpandedSidebarElements } from './components/SidebarElements';
import { ProgressLineChart } from './components/charts/ProgressLineChart';
import { ProjectContext, ProjectContextProvider } from './contexts/ProjectContext';
import { EntriesApprovalContext, EntriesApprovalContextProvider } from './contexts/EntriesApprovalContext';
import { useEntriesApprovals } from './hooks/useEntriesApprovals';

const Project = () => {
  const [isEditProjectModalOpen, setEditProjectModalOpen] = useState(false);

  const { rightMenuOpen } = useContext(RightMenuContext);
  const { projectData, entriesData, projectLoading, entriesLoading } = useContext(ProjectContext);
  const { approvalDetailsRef } = useContext(EntriesApprovalContext);
  const generateTableRowModifiers = useEntriesApprovals();

  if (projectLoading) {
    return <LoadingPlaceholder />;
  }

  const range = projectData.project.start && projectData.project.end
    ? `${moment(Number(projectData.project.start)).format('DD.MM')} - ${moment(Number(projectData.project.end)).format('DD.MM')}`
    : null;

  const header = {
    text: projectData.project.name,
    subheader: (<HorizontalList items={[projectData.project.code, range]} />),
    buttons: [{
      component: StatusDropdown,
      project: projectData.project,
    }, {
      component: GetReportButton,
      reportOn: { project: projectData.project.id },
    }, {
      text: 'Edit project',
      color: 'teal',
      onClick: () => setEditProjectModalOpen(true),
    }],
  };

  const projectCompleted = projectData.project.status === 'completed';
  const entriesTableModifiers = !projectCompleted
    ? generateTableRowModifiers
    : null;

  return (
    <CommonLayout
      header={header}

      surface={
        <React.Fragment>
          {
            isEditProjectModalOpen ? (
              <EditProjectModal
                isOpen={isEditProjectModalOpen}
                setOpen={setEditProjectModalOpen}
                project={projectData.project}
                entries={entriesData.entries}
              />
            ) : null
          }

          <ProgressLineChart />

          {
            !projectCompleted && entriesData.entries && entriesData.entries.length > 0 && (
              <ApprovalDetails />
            )
          }
          <Ref innerRef={approvalDetailsRef}>
            <EntriesTable
              hideProjects
              enableApprovals={!projectCompleted}
              entriesData={entriesData}
              entriesLoading={entriesLoading}
              applyModifiers={entriesTableModifiers}
            />
          </Ref>
        </React.Fragment>
      }

      rightMenu={
        rightMenuOpen ? (
          <ExpandedSidebarElements
            project={projectData.project}
          />
        ) : (
          <CollapsedSidebarElements
            project={projectData.project}
          />
        )
      }
    />
  );
};

export default () => {
  return (
    <ProjectContextProvider>
      <EntriesApprovalContextProvider>
        <Project />
      </EntriesApprovalContextProvider>
    </ProjectContextProvider>
  );
};
