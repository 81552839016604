import React, { useState } from 'react';
import { Tab } from 'semantic-ui-react';

import CommonLayout from '../../layouts/CommonLayout';
import { CreateAccountModal } from '../../modals/createAccountModal';
import { AccountsTable } from './components/AccountsTable';

const Accounts = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const panes = [
    { menuItem: 'Active', render: () => <AccountsTable /> },
  ];

  return (
    <CommonLayout
      header={{
        text: 'Accounts',
        buttons: [{
          text: 'Invite new member',
          color: 'teal',
          onClick: () => setModalOpen(true),
        }]
      }}

      surface={
        <React.Fragment>
          {
            isModalOpen ? (
              <CreateAccountModal
                isOpen={isModalOpen}
                setOpen={setModalOpen}
              />
            ) : null
          }

          <Tab
            menu={{ secondary: true, pointing: true, style: { borderBottomWidth: '1px', margin: '20px 0 0' } }}
            panes={panes}
          />
        </React.Fragment>
      }
    />
  );
};

export default Accounts;
