import React, { useState } from 'react';
import { Tab } from 'semantic-ui-react';

import CommonLayout from '../../layouts/CommonLayout';
import CreateProjectModal from '../../modals/createProjectModal';
import {
  AllProjects,
  ActiveProjects,
  CompletedProjects,
  PendingProjects,
} from './components/ProjectsTable';

const Projects = () => {
  const [ isModalOpen, setModalOpen ] = useState(false);

  const panes = [
    { menuItem: 'All', render: () => <AllProjects /> },
    { menuItem: 'Pending', render: () => <PendingProjects /> },
    { menuItem: 'Active', render: () => <ActiveProjects /> },
    { menuItem: 'Completed', render: () => <CompletedProjects /> },
  ];

  return (
    <CommonLayout
      header={{
        text: 'Projects',
        buttons: [{
          text: 'Create new project',
          color: 'teal',
          onClick: () => setModalOpen(true),
        }]
      }}

      surface={
        <React.Fragment>
          {
            isModalOpen ? (
              <CreateProjectModal
                isOpen={isModalOpen}
                setOpen={setModalOpen}
              />
            ) : null
          }
          <Tab
            menu={{ secondary: true, pointing: true, style: { borderBottomWidth: '1px', margin: '20px 0 0' } }}
            panes={panes}
          />
        </React.Fragment>
      }
    />
  );
};

export default Projects;
