import React from 'react';
import { Placeholder, Container, Segment } from 'semantic-ui-react';

import CommonLayout from '../../layouts/CommonLayout';

const LoadingPlaceholder = () => {
  return (
    <CommonLayout
      header={{
        text: 'Loading...',
        buttons: [{
          text: 'Placeholder text',
          color: 'grey',
          loading: true
        }, {
          text: 'Loading',
          color: 'grey',
          loading: true
        }],
      }}

      surface={
        <Container as={Segment} basic padded>
          <br />
          <br />
          <Placeholder fluid>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </Container>
      }
    />
  );
}

export default LoadingPlaceholder;
