import React from 'react'
import { Button, Image, Header, Popup, Segment } from 'semantic-ui-react';

import AppImage from '../../../assets/mobile.png';

export const AppSegment = () => {
  return (
    <Segment
      basic
      vertical
      textAlign='center'
      style={{ padding: '8em 0em' }}
    >
      <Header as='h3' style={{ fontSize: '2em' }}>
        Fast and reliable activity logging from any device.
      </Header>
      <p style={{ fontSize: '1.33em' }}>
        Easy to start. Real time communication for greater visibility. And it works offline.
      </p>
      <a target='_blank' href='https://play.google.com/store/apps/details?id=com.onejoule'>
        <Button
          icon='google play'
          color='green'
          size='small'
          content='Google Play'
        />
      </a>

      <a target='_blank' href='https://apps.apple.com/us/app/1joule-edge/id1500440308'>
        <Button
          size='small'
          color='black'
          icon='app store ios'
          content='App Store'
        />
      </a>

      <Image
        size='medium'
        src={AppImage}
        style={styles.image}
      />
    </Segment>
  );
}

const styles = {
  image: {
    margin: 'auto',
    border: '1px solid rgba(34,36,38,.15)',
    borderRadius: '6px',
    marginTop: '25px',
  },
};
