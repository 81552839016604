import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import moment from 'moment';

import { Exclamation } from '../../../components/Exclamation';

export const DeviceInfoTable = ({ device }) => {
  const notLinkedExclamation = (
    <Exclamation
      title='This device is not linked to a physical device!'
      text='Not linked'
    />
  );

  const deviceTableRows = [{
    key: 'Created',
    value: moment(Number(device.createdAt)).calendar(),
  }, {
    key: 'Linked',
    value: device.session ? moment(Number(device.session.createdAt)).calendar() : notLinkedExclamation,
  }].map(({ key, value }) => (
    <Table.Row key={key}>
      <Table.Cell>{ key }</Table.Cell>
      <Table.Cell textAlign='right'>{ value }</Table.Cell>
    </Table.Row>
  ));

  return (
    <Table unstackable basic>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell colSpan='2'><Icon name='mobile alternate' /> Device</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{ deviceTableRows }</Table.Body>
    </Table>
  );
};
