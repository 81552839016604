import React, { useContext } from 'react';
import { Divider, Message, Icon, Image, Segment, Grid, } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import LogoSmall from '../assets/logo_small.png';
import { AppContext } from '../contexts/AppContext';

export const AccountLayout = ({
  children,
  headerText,
  headerDescription,
  showForgotPassword = false,
  showAlreadySignedUp = false,
}) => {
  const { mobile } = useContext(AppContext);

  return (
    <Grid
      style={styles.grid}
      verticalAlign='middle'
      textAlign='center'
    >
      <Grid.Column
        style={!mobile ? styles.column : null}
        width={!mobile ? 6 : null}
      >
          <Segment
            basic
            textAlign='left'
          >
            <Image
              as={Link}
              to='/'
              src={LogoSmall}
              size='mini'
            />

            { headerText ? (
              <h3>{ headerText }</h3>
            ) : null }

            { headerDescription ? (
              <p>{ headerDescription }</p>
            ) : null }

            <Divider hidden />

            { children }

            <Divider section hidden />

            {
              showForgotPassword ? (
                <Message warning>
                  <Icon name='help' />
                  Forgot your password? <Link to='/forgot'>Reset password here</Link>
                </Message>
              ) : null
            }

            {
              showAlreadySignedUp ? (
                <Message warning>
                  <Icon name='help' />
                  Already signed up? <Link to='/signin'>Sign in here</Link>
                </Message>
              ) : null
            }
          </Segment>
      </Grid.Column>
    </Grid>
  );
}

const styles = {
  grid: {
    height: '100%',
    margin: 0,
  },
  column: {
    minWidth: '350px',
    maxWidth: '500px',
  },
};

