import React, { useState } from 'react';
import { Divider, Message, Button, Form, Modal } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';

import UPDATE_WORKER from '../graphql/mutations/updateWorker';
import { parseErrors } from '../libs/errors';

const EditWorkerModal = ({ isOpen, setOpen, workerId, worker }) => {
  const close = () => setOpen(false);

  const [formData, setFormData] = useState({
    id: Number(workerId),
    name: worker.name,
    code: worker.code || '',
    phoneNumber: worker.phoneNumber || '',
  });

  const [mutationLoading, setMutationLoading] = useState(false);
  const [mutationErrors, setMutationErrors] = useState(null);

  const [updateWorkerMutation] = useMutation(UPDATE_WORKER, {
    variables: {
      entity: formData,
    },
    refetchQueries: ['getWorker'],
  });

  async function updateWorker() {
    try {
      setMutationLoading(true);
      await updateWorkerMutation();

      close();
    } catch (e) {
      setMutationLoading(false);

      const errors = parseErrors(e);
      setMutationErrors(errors);
    }
  }

  function handleChange(e, { name, value, checked }) {
    setFormData({
      ...formData,
      [name]: checked !== undefined ? checked : value,
    });
  }

  const errorMessage = mutationErrors && mutationErrors.general ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ mutationErrors.general }</p>
    </Message>
  ) : null;

  return (
    <Modal
      size='tiny'
      dimmer={'blurring'}
      open={isOpen}
      onClose={close}
    >
      <Modal.Header>Edit { worker.name }</Modal.Header>

      <Modal.Content>
        { errorMessage }
        <Form
          size='small'
          autoComplete='off'
          id='modal-form'
        >
          <Form.Input
            fluid
            required
            name='name'
            label='Name'
            value={formData.name}
            error={mutationErrors ? mutationErrors.name : null}
            onChange={handleChange}
          />

          <Form.Input
            fluid
            name='code'
            label='Code'
            value={formData.code}
            error={mutationErrors ? mutationErrors.code : null}
            onChange={handleChange}
          />

          <Form.Input
            fluid
            name='phoneNumber'
            label='Phone number'
            value={formData.phoneNumber}
            error={mutationErrors ? mutationErrors.phoneNumber : null}
            onChange={handleChange}
          />

        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button
          content='Cancel'
          onClick={close}
        />
        <Button
          form='modal-form'
          color='teal'
          icon='save'
          content='Save'
          loading={mutationLoading}
          disabled={mutationLoading}
          onClick={updateWorker}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default EditWorkerModal;
