import gql from 'graphql-tag';

const SIGN_UP = gql`
  mutation (
    $data: SignUpInput!
  ) {
    signUp (
      data: $data
    )
  }
`;

export default SIGN_UP;
