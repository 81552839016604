import React from 'react';

export const HorizontalList = ({ items, noDot }) => {
  const listItems = items.filter(item => !!item).map((item, i, arr) => {
    const divider = i !== arr.length - 1 ? ' · ' : null;

    return (
      <span
        key={i}
        // i === 1 means that styile is applied only to second element
        // Explanation: this is needed to ensure a space between the name and labels next to the name,
        // But there must not be additonal space between labels.
        style={i === 1 && noDot ? styles.listItem : null}
      >
        { item }
        { !noDot ? divider : null }
      </span>
    );
  });

  return (
    <div>
      { listItems }
    </div>
  );
}

const styles = {
  listItem: {
    marginLeft: '10px',
  },
}
