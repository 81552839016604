import { todayValueOf } from './common';

export function calculatePrediction(totalSpentTime, totalSpentTimeFirstDay, minX, maxX) {
  const x1 = minX;
  const y1 = totalSpentTimeFirstDay;

  const x2 = todayValueOf();
  const y2 = totalSpentTime;

  const x = maxX;

  const m = (y2 - y1) / (x2 - x1);
  const y = m * (x - x1) + y1;

  if (y < y2) {
    return y2;
  }

  return y;
}
