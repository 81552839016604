import gql from 'graphql-tag';

const GET_ACCOUNTS = gql`
  query getAccounts {
    accounts {
      id
      email
      root
      invitationAccepted
    }
  }
`;

export default GET_ACCOUNTS;
