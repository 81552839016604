import React from 'react';
import { Button } from 'semantic-ui-react';
import { useHistory } from 'react-router';

export const GetReportButton = ({ reportOn, mobile, ...rest }) => {
  const history = useHistory();

  function handleClick() {
    history.push({
      pathname: '/reporting',
      reportOn,
    })
  }

  return (
    <Button
      basic
      fluid={mobile}
      content='Get report'
      color='teal'
      onClick={handleClick}
      {...rest}
    />
  );
}
