import React, { useState } from 'react';
import { Form, Button, Message, } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import FORGOT_PASSWORD from '../../graphql/mutations/forgotPassword';
import { parseErrors } from '../../libs/errors';
import { AccountLayout } from '../../layouts/AccountLayout';

const ForgotPassword = ({ match }) => {
  const [formData, setFormData] = useState({
    email: '',
  });
  const [mutationErrors, setMutationErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [forgotPasswordMutation] = useMutation(FORGOT_PASSWORD, {
    variables: formData,
  });

  async function forgotPassword() {
    setLoading(true);

    try {
      await forgotPasswordMutation();

      setSuccess(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      const errors = parseErrors(e);
      setMutationErrors(errors);
    }
  }

  function handleChange(e, { name, value }) {
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const errorMessage = mutationErrors && mutationErrors.general ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ mutationErrors.general }</p>
    </Message>
  ) : null;

  const successMessage = success ? (
    <Message positive>
      <Message.Header>Success!</Message.Header>
      <p>We've sent password reset instructions to your email address!</p>
    </Message>
  ) : null;

  return (
    <AccountLayout
      headerText='Reset password'
      headerDescription='Instructions will be sent to your email.'
    >
      { errorMessage }
      { successMessage }
      {
        !success ? (
          <Form
            size='small'
            noValidate
          >
            <Form.Input
              fluid
              name='email'
              type='email'
              icon='at'
              iconPosition='left'
              placeholder='Email'
              value={formData.email}
              error={mutationErrors ? mutationErrors.email : null}
              onChange={handleChange}
            />

            <br />

            <Button
              fluid
              content='Continue'
              color='teal'
              size='small'
              onClick={forgotPassword}
              loading={loading}
              disabled={loading}
            />
          </Form>
        ) : null
      }
    </AccountLayout>
  );
};

export default withRouter(ForgotPassword);
