import gql from 'graphql-tag';

const ACCEPT_INVITATION = gql`
  mutation acceptInvitation (
    $data: AcceptInvitationInput!
  ) {
    acceptInvitation (
      data: $data
    )
  }
`;

export default ACCEPT_INVITATION;
