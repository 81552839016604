import React, { useState } from 'react';
import { Header, Modal, Confirm, Button, Dropdown } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';

import UPDATE_PROJECT from '../../../graphql/mutations/updateProject';

const projectStatusOptions = [
  { key: 'pending', text: 'Pending', value: 'pending' },
  { key: 'active', text: 'Active', value: 'active' },
  { key: 'completed', text: 'Completed', value: 'completed' },
];

const color = {
  pending: 'grey',
  active: 'olive',
  completed: 'teal',
};

const text = {
  pending: 'Pending',
  active: 'Active',
  completed: 'Completed',
};

export const StatusDropdown = ({ project, mobile, ...rest }) => {
  const [newStatus, setNewStatus] = useState(null);
  const [status, setStatus] = useState(project.status);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [mutationLoading, setMutationLoading] = useState(false);

  const [updateStatusToPendingMutation] = useMutation(UPDATE_PROJECT, {
    variables: {
      entity: {
        id: Number(project.id),
        status: 'pending',
      },
    },
    refetchQueries: ['getProject', 'getProjects', 'getEntries'],
  });

  const [updateStatusToActiveMutation] = useMutation(UPDATE_PROJECT, {
    variables: {
      entity: {
        id: Number(project.id),
        status: 'active',
      },
    },
    refetchQueries: ['getProject', 'getProjects', 'getEntries'],
  });

  const [updateStatusToCompletedMutation] = useMutation(UPDATE_PROJECT, {
    variables: {
      entity: {
        id: Number(project.id),
        status: 'completed',
      },
    },
    refetchQueries: ['getProject', 'getProjects', 'getEntries'],
  });

  async function updateProject(status) {
    try {
      setMutationLoading(true);

      switch (status) {
        case 'pending':
          await updateStatusToPendingMutation();
          break;
        case 'active':
          await updateStatusToActiveMutation();
          break;
        case 'completed':
          await updateStatusToCompletedMutation();
          break;
        default:
          setStatus(project.status);
          throw new Error('Unexpected error in startup dropdown.')
      }

      setMutationLoading(false);
    } catch (e) {
      setMutationLoading(false);
      setStatus(project.status);
    }
  }

  async function handleChange(e, { value }) {
    if (!mutationLoading && project.status !== value) {
      setNewStatus(value);
      setConfirmOpen(true);
    }
  }

  async function handleClick() {
    setConfirmOpen(false);
    await updateProject(newStatus);
    setStatus(newStatus);
    setNewStatus(null);
  };

  function renderConfirmHeader() {
    return (
      <Header
        icon='attention'
        content={`Change status to ${newStatus}?`}
      />
    );
  };

  function confirmationText() {
    return (
      <Modal.Content>
        {
          newStatus === 'completed' ? (
            <>
              All activities in this project will be stopped and approved.<br />
              This project won't be visible on mobile devices anymore.
              <br/>
              <br/>
              Continue?
            </>
          ) : null
        }

        {
          newStatus === 'active' ? (
            <>
              This project will appear on linked mobile devices.<br />
              Workers will be able to start activities in this project.
              <br/>
              <br/>
              Continue?
            </>
          ) : null
        }
      </Modal.Content>
    );
  }

  function renderConfirmButton() {
    return (
      <Button
        basic
        inverted
        color='teal'
        icon='checkmark'
        content='Yes'
        onClick={handleClick}
      />
    );
  }

  function renderCancelButton() {
    return (
      <Button
        basic
        inverted
        color='red'
        icon='remove'
        content='No'
        onClick={() => setConfirmOpen(false)}
      />
    );
  }

  return (
    <React.Fragment>
      <Dropdown
        floating
        fluid={mobile}
        as={Button}
        loading={mutationLoading}
        text={text[status]}
        color={color[status]}
        basic={status === 'pending'}
        {...rest}
      >
        <Dropdown.Menu>
          {projectStatusOptions.map(option => (
            <Dropdown.Item
              key={option.value}
              onClick={handleChange}
              {...option}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <Confirm
        basic
        size='tiny'
        content={confirmationText}
        header={renderConfirmHeader}
        confirmButton={renderConfirmButton}
        cancelButton={renderCancelButton}
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
      />
    </React.Fragment>
  );
}
