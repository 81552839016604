import React, { useState } from 'react';
import { Button, Confirm, Header, Modal, Popup } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';

import { GET_DEVICE } from '../../../graphql/queries/getDevice';
import { UNLINK_DEVICE } from '../../../graphql/mutations/unlinkDevice';
import LinkMobileDevice from '../../../modals/linkMobileDeviceModal';

export const LinkDeviceButton = ({ device, refetch, mobile, ...rest }) => {
  const [linkMobileDeviceModalOpen, setLinkMobileDeviceOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [mutationLoading, setMutationLoading] = useState(false);

  const [unlinkDeviceMutation] = useMutation(UNLINK_DEVICE, {
    variables: {
      deviceId: device.id,
    },
    refetchQueries: [{
      query: GET_DEVICE,
      variables: { deviceId: device.id },
    }],
  });

  async function unlinkDevice() {
    try {
      setMutationLoading(true);

      await unlinkDeviceMutation();

      setMutationLoading(false);
    } catch (e) {
      setMutationLoading(false);
    }
  }

  async function handleConfirmClick() {
    setConfirmOpen(false);

    if (!mutationLoading) {
      await unlinkDevice();
    }
  }

  const unlinkButton = (
    <Button
      basic
      fluid={mobile}
      loading={mutationLoading}
      content={mobile ? 'Unlink device' : null}
      color='red'
      icon={!mobile ? 'unlinkify' : null}
      onClick={() => setConfirmOpen(true)}
      {...rest}
    />
  );

  return (
    <React.Fragment>
      {
        linkMobileDeviceModalOpen ? (
          <LinkMobileDevice
            isOpen={linkMobileDeviceModalOpen}
            setOpen={setLinkMobileDeviceOpen}
            deviceId={device.id}
            linkedAt={device.linkedAt}
            refetchDevice={refetch}
          />
        ) : null
      }

      {
        device.session ? (
          <Confirm
            basic
            size='tiny'
            content={
              <Modal.Content>
                After unlinking the device will not be able to communiate with the management console anymore.
                <br/>
                <br/>
                Continue?
              </Modal.Content>
            }
            header={
              <Header
                icon='unlinkify'
                content={`Unlink ${device.name}?` }
              />
            }
            confirmButton={
              <Button
                basic
                inverted
                color='teal'
                icon='checkmark'
                content='Yes'
                onClick={handleConfirmClick}
              />
            }
            cancelButton={
              <Button
                basic
                inverted
                color='red'
                icon='remove'
                content='No'
                onClick={() => setConfirmOpen(false)}
              />
            }
            open={confirmOpen}
            onCancel={() => setConfirmOpen(false)}
          />
        ) : null
      }

      {
        device.session ? (
          <React.Fragment>
            {
              mobile ? (
                unlinkButton
              ) : (
                <Popup
                  inverted
                  size='small'
                  content='Unlink device'
                  trigger={unlinkButton} 
                />
              )
            }
          </React.Fragment>
        ) : (
          <Button
            basic
            fluid={mobile}
            loading={mutationLoading}
            disabled={device.archived}
            content='Link device'
            color='green'
            icon='linkify'
            onClick={() => setLinkMobileDeviceOpen(true)}
            {...rest}
          />
        )
      }
    </React.Fragment>
  );
}
