import React, { useState } from 'react';

export const AppContext = React.createContext();

export function AppContextProvider({ children }) {
  const [mobile, setMobile] = useState(false);
  const [error, setError] = useState(null);

  return (
    <AppContext.Provider
      value={{
        mobile,
        setMobile,

        error,
        setError,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
