import gql from 'graphql-tag';

export const UNLINK_DEVICE = gql`
  mutation unlinkDevice(
    $deviceId: Float!
  ) {
    unlinkDevice (
      deviceId: $deviceId,
    )
  }
`;
