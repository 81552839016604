import gql from 'graphql-tag';

const GET_WORKS = gql`
  query getWorks (
    $filters: WorkFilters
    $orderBy: WorkOrder
  ) {
    works (
      filters: $filters
      orderBy: $orderBy
    ) {
      id
      name
      code
      createdAt

      projects {
        id
        name
      }
    }
  }
`;

export default GET_WORKS;
