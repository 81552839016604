import React from 'react';
import moment from 'moment';
import { Table, Icon } from 'semantic-ui-react';

import { Exclamation } from '../../../components/Exclamation';

export const LinkedAtCell = ({ session }) => {
  const linkedAtText = (
    <p>
      {
        session ? (
          <React.Fragment>
            <Icon name='linkify' /> { moment(Number(session.createdAt)).calendar() }
          </React.Fragment>
        ) : (
          <Exclamation
            title='This device is not linked to a physical device!'
            text='Not linked'
          />
        )
      }
    </p>
  );

  return (
    <Table.Cell>
      { linkedAtText }
    </Table.Cell>
  );
};
