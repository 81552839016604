import React, { useContext, useEffect, useState } from 'react';
import { Table, Message, Loader, Icon, Segment, } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { AppContext } from '../../../contexts/AppContext';
import { LinkedAtCell } from './LinkedAtCell';
import GET_DEVICES from '../../../graphql/queries/getDevices';
import GET_PROJECTS from '../../../graphql/queries/getProjects';
import { useManipulators } from '../../../hooks/useManipulators';
import { assignedProjectsDropdown } from '../../../components/AssignedProjectsDropdown';

export const DevicesTable = ({ archived }) => {
  const { mobile } = useContext(AppContext);
  const [firstFetch, setFirstFetch] = useState(true);
  const [cachedData, setCachedData] = useState({ devices: [] });

  const { data: projectsData = {}, loading: projectsLoading } = useQuery(GET_PROJECTS);

  const { filters, orderBy, manipulator } = useManipulators({
    filters: [
      { name: 'name', type: 'input' },
      {
        name: 'projects',
        type: 'select',
        multiple: true,
        options: projectsData.projects,
      },
    ],
    orderBy: {
      options: [
        { text: 'Created date', value: 'createdAt' },
        { text: 'Last updated date', value: 'updatedAt' },
        { text: 'Name', value: 'name' },
      ],
    },
  });

  const { data: devicesData = {}, devicesLoading } = useQuery(GET_DEVICES, {
    fetchPolicy: 'cache-and-network',
    variables: archived ? { filters: { archived: true } } : { filters, orderBy },
  });

  const loading = projectsLoading || devicesLoading;

  useEffect(() => {
    if (Object.keys(devicesData).length) {
      setCachedData(devicesData);
    }
  }, [devicesData]);

  useEffect(() => {
    if (!loading && firstFetch) {
      setFirstFetch(false);
    }
  }, [loading, firstFetch]);

  if (loading && firstFetch) {
    return (
      <Segment basic padded='very'>
        <Loader active />
      </Segment>
    );
  }

  const noResults = !cachedData.devices.length ? (
    <Message>
      <Message.Header>No results found</Message.Header>
      <p>{
        archived
          ? 'No archived devices.'
          : 'Create new items or specify different filters.'
      }</p>
    </Message>
  ) : null;

  return (
    <React.Fragment>
      { !archived ? manipulator : null }
      <Table unstackable basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell><Icon name='mobile alternate' /> Device</Table.HeaderCell>
            <Table.HeaderCell width={5} textAlign='right'><Icon name='clipboard outline' /> In projects</Table.HeaderCell>
            {
              !mobile ? (
                <Table.HeaderCell width={3}><Icon name='linkify' /> Linked</Table.HeaderCell>
              ) : null
            }
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            cachedData.devices.map(({ id, name, session, projects }) => (
              <Table.Row
                key={id}
              >
                <Table.Cell>
                  <Link
                    to={`/devices/${id}`}
                  >
                  { name }
                  </Link>
                </Table.Cell>
                <Table.Cell textAlign='right'>{ assignedProjectsDropdown(projects) }</Table.Cell>
                {
                  !mobile ? (
                    <LinkedAtCell session={session} />
                  ) : null
                }
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
      { noResults }
    </React.Fragment>
  );
};
