import React, { useState } from 'react';
import { Message, Button, Form, Modal } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';

import UPDATE_DEVICE from '../graphql/mutations/updateDevice';

const EditDeviceModal = ({ isOpen, setOpen, deviceId, device }) => {
  const close = () => setOpen(false);

  const [ name, setName ] = useState(device.name);

  const [ mutationLoading, setMutationLoading ] = useState(false);
  const [ mutationError, setMutationError ] = useState(null);

  const [updateDeviceMutation] = useMutation(UPDATE_DEVICE, {
    variables: {
      entity: {
        id: Number(deviceId),
        name,
      },
    },
    refetchQueries: ['getDevice'],
  });

  async function updateDevice() {
    try {
      setMutationLoading(true);
      await updateDeviceMutation();

      close();
    } catch (e) {
      setMutationLoading(false);

      if (e.networkError) {
        setMutationError('Network error. Check your connection.');
      } else if (e.graphQLErrors) {
        setMutationError(e.graphQLErrors[0].message);
      } else {
        setMutationError(e.message);
      }
    }
  }

  const errorMessage = mutationError ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ mutationError }</p>
    </Message>
  ) : null;

  return (
    <Modal
      size='tiny'
      dimmer={'blurring'}
      open={isOpen}
      onClose={close}
    >
      <Modal.Header>Edit { device.name }</Modal.Header>

      <Modal.Content>
        { errorMessage }
        <Form
          size='small'
          autoComplete='off'
          id='modal-form'
        >
          <Form.Input
            fluid
            label='Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button
          content='Cancel'
          onClick={close}
        />
        <Button
          form='modal-form'
          color='teal'
          icon='save'
          content='Save'
          loading={mutationLoading}
          disabled={mutationLoading}
          onClick={updateDevice}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default EditDeviceModal;
