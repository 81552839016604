import React, { useContext, useEffect, useState } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';

import { AppContext } from './AppContext';

export const LeftMenuContext = React.createContext();

export function LeftMenuContextProvider({ children }) {
  const { mobile } = useContext(AppContext);
  const [initialLoad, setInitialLoad] = useState(true);

  const [leftMenuOpen, setLeftMenuOpen] = useLocalStorage('leftMenuOpen');

  const closeLeftMenu = () => setLeftMenuOpen('closed');
  const openLeftMenu = () => setLeftMenuOpen('open');

  useEffect(() => {
    if (mobile && initialLoad) {
      setLeftMenuOpen(false);
      setInitialLoad(false);
    }
  }, [mobile, initialLoad, setLeftMenuOpen]);

  useEffect(() => {
    if (!mobile && leftMenuOpen === undefined) {
      setLeftMenuOpen('open');
    }
  }, [mobile, leftMenuOpen, setLeftMenuOpen]);

  return (
    <LeftMenuContext.Provider
      value={{
        leftMenuOpen: leftMenuOpen === 'open',
        closeLeftMenu,
        openLeftMenu,
      }}
    >
      {children}
    </LeftMenuContext.Provider>
  );
}
