import React, { useEffect, useState } from 'react';
import { Divider, Message, Button, Form, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';

import UPDATE_PROJECT from '../graphql/mutations/updateProject';
import GET_DEVICES from '../graphql/queries/getDevices';
import GET_WORKERS from '../graphql/queries/getWorkers';
import GET_WORKS from '../graphql/queries/getWorks';
import { parseErrors } from '../libs/errors';

function mapEntityToDropdownOptions(entities = []) {
  return entities.map(entity => ({
    key: entity.id,
    text: entity.name,
    value: entity.id,
  }));
}

function mapEntityToSelectedOptions(entities = []) {
  return entities.map(entity => entity.id);
}

function openEntryByEntity(entries, entity, workerId) {
  try {
    const index = entries.findIndex(entry => !entry.end && entry[entity].id === workerId);

    return index > -1;
  } catch (e) {
    return false;
  }
}

function defaultFormData(project) {
  const {
    works: worksInProject,
    workers: workersInProject,
    devices: devicesInProject,
  } = project;

  return {
    id: project.id,
    name: project.name,
    code: project.code,
    start: project.start ? moment(Number(project.start)).format('DD.MM.YYYY') : '',
    end: project.end ? moment(Number(project.end)).format('DD.MM.YYYY') : '',
    budget: project.budget ? project.budget : '',
    works: mapEntityToSelectedOptions(worksInProject),
    workers: mapEntityToSelectedOptions(workersInProject),
    devices: mapEntityToSelectedOptions(devicesInProject),
  };
}

const EditProjectModal = ({ isOpen, setOpen, project, entries }) => {
  const close = () => setOpen(false);

  const [formData, setFormData] = useState(defaultFormData(project));

  const [mutationLoading, setMutationLoading] = useState(false);
  const [mutationErrors, setMutationErrors] = useState(null);

  const { data: worksData = {}, loading: worksLoading } = useQuery(GET_WORKS);
  const { data: workersData = {}, loading: workersLoading } = useQuery(GET_WORKERS);
  const { data: devicesData = {}, loading: devicesLoading } = useQuery(GET_DEVICES);

  const loading = worksLoading || workersLoading || devicesLoading;

  const [updateProjectMutation] = useMutation(UPDATE_PROJECT, {
    variables: {
      entity: {
        ...formData,
        budget: Number(formData.budget),
      },
    },
    refetchQueries: ['getProject'],
  });

  async function updateProject() {
    try {
      setMutationLoading(true);
      await updateProjectMutation();

      close();
    } catch (e) {
      setMutationLoading(false);

      const errors = parseErrors(e);
      setMutationErrors(errors);
    }
  }

  function renderLabel({ value, text }, entity) {
    const hasOpenEntry = openEntryByEntity(entries, entity, value);

    const hasPhoneNumber = entity === 'worker' ? workersData.workers.find((x) => x.id === value).phoneNumber : false;

    return {
      content: text,
      icon: hasPhoneNumber && 'mobile alternate',
      ...(hasOpenEntry && {
        removeIcon: false,
        color: 'olive',
      }),
      detail: hasOpenEntry && {
        content: 'active now',
      },
    };
  }

  function handleChange(e, { name, value }) {
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  function handleDropdownChange({ name, value }, entity) {
    const removedItem = formData.workers.filter((i) => value.indexOf(i) < 0)[0];
    const hasOpenEntry = openEntryByEntity(entries, entity, removedItem);

    if (!hasOpenEntry) {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  }

  /*
   * The purpose of this effect is to solve the following problem:
   *
   * "Worker is assigned to a project, but has no open entries.
   * On project edit modal window, unassign the worker, but don't click save yet.
   * Create open entry for this worker on the device.
   * Worker should appear back as assigned and become not possible to unassign since he has an open entry.
   * The same problem applies to devices."
   */
  useEffect(() => {
    const activeWorkerIds = project.workers
      .filter(worker => openEntryByEntity(entries, 'worker', worker.id))
      .map(worker => worker.id);

    const activeDeviceIds = project.devices
      .filter(device => openEntryByEntity(entries, 'device', device.id))
      .map(worker => worker.id);

    const activeButNotAssignedWorkerIds = activeWorkerIds.filter(i => formData.workers.indexOf(i) < 0);
    const activeButNotAssignedDeviceIds = activeDeviceIds.filter(i => formData.devices.indexOf(i) < 0);

    if (activeButNotAssignedWorkerIds.length || activeButNotAssignedDeviceIds.length) {
      setFormData({
        ...formData,
        ...(activeButNotAssignedWorkerIds.length ? { workers: [...formData.workers, ...activeButNotAssignedWorkerIds] } : null),
        ...(activeButNotAssignedDeviceIds.length ? { devices: [...formData.devices, ...activeButNotAssignedDeviceIds] } : null),
      });
    }
  }, [project, formData]);

  const errorMessage = mutationErrors && mutationErrors.general ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ mutationErrors.general }</p>
    </Message>
  ) : null;

  return (
    <Modal
      size='tiny'
      dimmer={'blurring'}
      centered={false}
      open={isOpen}
      onClose={close}
    >
      <Modal.Header>Edit { project.name }</Modal.Header>

      <Modal.Content scrolling>
        {
          loading ? (
            <Dimmer active inverted>
              <Loader inverted content='Loading' />
            </Dimmer>
          ) : null
        }
        { errorMessage }
        <Form
          size='small'
          autoComplete='off'
          id='modal-form'
        >
          <Form.Input
            fluid
            required
            name='name'
            label='Name'
            value={formData.name}
            error={mutationErrors ? mutationErrors.name : null}
            onChange={handleChange}
          />

          <Form.Input
            fluid
            name='code'
            label='Code'
            value={formData.code}
            error={mutationErrors ? mutationErrors.code : null}
            onChange={handleChange}
          />

          <Divider hidden />

          <Form.Group>
            <DateInput
              width={6}
              name='start'
              label='Start date'
              animation='none'
              dateFormat='DD.MM.YYYY'
              popupPosition='bottom right'
              value={formData.start}
              closable={true}
              preserveViewMode={false}
              error={mutationErrors ? mutationErrors.start : null}
              onChange={handleChange}
            />

            <DateInput
              width={6}
              name='end'
              label='End date'
              animation='none'
              dateFormat='DD.MM.YYYY'
              popupPosition='bottom right'
              value={formData.end}
              closable={true}
              preserveViewMode={false}
              error={mutationErrors ? mutationErrors.end : null}
              onChange={handleChange}
            />

            <Form.Input
              width={4}
              label='Time budget, hrs'
              name='budget'
              type='number'
              min={0}
              value={formData.budget}
              error={mutationErrors ? mutationErrors.budget : null}
              onChange={handleChange}
            />
          </Form.Group>

          <Divider hidden />

          <Form.Dropdown
            fluid
            multiple
            upward
            search
            selection
            name='works'
            label='Assigned Works'
            placeholder='Choose works'
            value={formData.works}
            options={mapEntityToDropdownOptions(worksData.works)}
            error={mutationErrors ? mutationErrors.works : null}
            onChange={handleChange}
          />

          <Form.Dropdown
            fluid
            multiple
            upward
            search
            selection
            name='workers'
            label='Assigned Workers'
            placeholder='Choose workers'
            value={formData.workers}
            options={mapEntityToDropdownOptions(workersData.workers)}
            renderLabel={(label) => renderLabel(label, 'worker')}
            error={mutationErrors ? mutationErrors.workers : null}
            onChange={(e, data) => handleDropdownChange(data, 'worker')}
          />

          <Form.Dropdown
            fluid
            multiple
            upward
            search
            selection
            name='devices'
            label='Assigned Devices'
            placeholder='Choose devices'
            value={formData.devices}
            options={mapEntityToDropdownOptions(devicesData.devices)}
            renderLabel={(label) => renderLabel(label, 'device')}
            error={mutationErrors ? mutationErrors.devices : null}
            onChange={(e, data) => handleDropdownChange(data, 'worker')}
          />
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button
          basic
          content='Undo changes' 
          floated='left'
          icon='redo'
          style={styles.restoreButton}
          onClick={() => setFormData(defaultFormData(project))}
        />
        <Button
          content='Cancel'
          onClick={close}
        />
        <Button
          form='modal-form'
          color='teal'
          icon='save'
          content='Save'
          loading={mutationLoading}
          disabled={mutationLoading}
          onClick={updateProject}
        />
      </Modal.Actions>
    </Modal>
  );
};

const styles = {
  restoreButton: {
    marginLeft: '0.25em',
  },
}

export default EditProjectModal;
