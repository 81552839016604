import React from 'react'
import { Container, Segment } from 'semantic-ui-react';

import UpperMenu from './components/UpperMenu';
import { AppSegment } from './components/AppSegment';
import { PresentationSegment } from './components/PresentationSegment';
import { FeaturesSegment } from './components/FeaturesSegment';
import { PricingSegment } from './components/PricingSegment';

const HomepageLayout = () => {
  return (
    <React.Fragment>
      <UpperMenu />
      <PresentationSegment />
      <AppSegment />
      <FeaturesSegment />
      <PricingSegment />

      <Segment inverted vertical style={{ padding: '5em 0em' }}>
        <Container>
          1joule.tools
        </Container>
      </Segment>
    </React.Fragment>
  );
}

export default HomepageLayout;
