import gql from 'graphql-tag';

export const ARCHIVE_WORK = gql`
  mutation (
    $entity: EntityArchiveInput!
  ) {
    archiveWork (
      entity: $entity,
    )
  }
`;
