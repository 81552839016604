import React from 'react';
import { Icon, Header } from 'semantic-ui-react';

export const Disclaimer = ({ icon, content }) => {
  return (
    <Header
      icon
      as='h4'
      textAlign='center'
      style={styles.header}
    >
      <Icon
        name={icon}
        color='orange'
        style={styles.icon}
      />
      <Header.Content>
        { content }
      </Header.Content>
    </Header>
  );
}

const styles = {
  header: {
    padding: '30px 0',
  },
  icon: {
    marginBottom: '20px',
  }
};
