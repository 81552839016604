import gql from 'graphql-tag';

const GET_DEVICES = gql`
  query getDevices (
    $filters: DeviceFilters
    $orderBy: DeviceOrder
  ) {
    devices (
      filters: $filters
      orderBy: $orderBy
    ) {
      id
      name
      archived
      createdAt

      session {
        createdAt
      }

      projects {
        id
        name
      }
    }
  }
`;

export default GET_DEVICES;
