import React, { useState } from 'react';
import { Divider, Message, Button, Form, Modal } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';

import UPDATE_WORK from '../graphql/mutations/updateWork';
import { parseErrors } from '../libs/errors';

const WORK_UNIT_OPTIONS = ['kg', 'm', 'm2', 'm3', 'pcs'].map((x) => ({
  key: x,
  text: x,
  value: x,
}));

const EditWorkModal = ({ isOpen, setOpen, workId, work }) => {
  const close = () => setOpen(false);

  const [formData, setFormData] = useState({
    id: Number(workId),
    name: work.name,
    code: work.code || '',
    unit: work.unit || null,
    requireUnitInput: work.requireUnitInput,
  });

  const [mutationLoading, setMutationLoading] = useState(false);
  const [mutationErrors, setMutationErrors] = useState(null);

  const [updateWorkMutation] = useMutation(UPDATE_WORK, {
    variables: {
      entity: formData,
    },
    refetchQueries: ['getWork'],
  });

  async function updateWork() {
    try {
      setMutationLoading(true);
      await updateWorkMutation();

      close();
    } catch (e) {
      setMutationLoading(false);

      const errors = parseErrors(e);
      setMutationErrors(errors);
    }
  }

  function handleChange(e, { name, value, checked }) {
    setFormData({
      ...formData,
      [name]: checked === undefined ? value : checked,
    });
  }

  const errorMessage = mutationErrors && mutationErrors.general ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ mutationErrors.general }</p>
    </Message>
  ) : null;

  return (
    <Modal
      size='tiny'
      dimmer={'blurring'}
      open={isOpen}
      onClose={close}
    >
      <Modal.Header>Edit { work.name }</Modal.Header>

      <Modal.Content>
        { errorMessage }
        <Form
          size='small'
          autoComplete='off'
          id='modal-form'
        >
          <Form.Input
            fluid
            required
            name='name'
            label='Name'
            value={formData.name}
            error={mutationErrors ? mutationErrors.name : null}
            onChange={handleChange}
          />

          <Form.Input
            fluid
            name='code'
            label='Code'
            value={formData.code}
            error={mutationErrors ? mutationErrors.code : null}
            onChange={handleChange}
          />

          <Divider hidden />

          <Form.Checkbox
            toggle
            name='requireUnitInput'
            label='Ask for fulfilled units when completing activity'
            checked={formData.requireUnitInput}
            error={mutationErrors ? mutationErrors.requireUnitInput : null}
            onChange={handleChange}
          />

          <Form.Dropdown
            required
            fluid
            upward
            selection
            name='unit'
            label='Work unit'
            value={formData.unit}
            disabled={!formData.requireUnitInput}
            options={WORK_UNIT_OPTIONS}
            error={mutationErrors ? mutationErrors.unit : null}
            onChange={handleChange}
          />
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button
          content='Cancel'
          onClick={close}
        />
        <Button
          form='modal-form'
          color='teal'
          icon='save'
          content='Save'
          loading={mutationLoading}
          disabled={mutationLoading}
          onClick={updateWork}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default EditWorkModal;
