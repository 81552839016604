import React, { useState } from 'react';
import { Message, Tab } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { useQuery } from '@apollo/react-hooks';

import { ArchiveButton } from '../../components/ArchiveButton';
import { GetReportButton } from '../../components/GetReportButton';
import CommonLayout from '../../layouts/CommonLayout';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import EditDeviceModal from '../../modals/editDeviceModal';
import { GET_DEVICE } from '../../graphql/queries/getDevice';
import { ARCHIVE_DEVICE } from '../../graphql/mutations/archiveDevice';
import { LinkDeviceButton } from './components/LinkDeviceButton';
import { OverviewTab } from './components/OverviewTab';
import { ActivityTab } from './components/ActivityTab';

const Device = ({ match }) => {
  const { deviceId } = match.params;

  const [editDeviceModalOpen, setEditDevicetModalOpen] = useState(false);

  const { data = {}, loading, refetch } = useQuery(GET_DEVICE, {
    variables: {
      deviceId: Number(deviceId),
    },
  });

  if (loading) {
    return <LoadingPlaceholder />;
  }

  const panes = [
    { menuItem: 'Overview', render: () => <OverviewTab device={data.device} /> },
    { menuItem: 'Activity', render: () => <ActivityTab device={data.device} /> },
  ];

  const linkWarning = !data.device.archived && !data.device.session ? (
    <Message warning>
      <Message.Header>Mobile device is not linked!</Message.Header>
      <p>In order to use this device, finish the linking process on your mobile device.</p>
    </Message>
  ) : null;

  const archivedMessage = data.device.archived ? (
    <Message warning>
      <Message.Header>Device is archived.</Message.Header>
      <Message.Content>Archived devices will not appear in projects and will not be able to communicate with the service.</Message.Content>
    </Message>
  ) : null;

  return (
    <CommonLayout
      header={{
        text: data.device.name,
        buttons: [{
          component: ArchiveButton,
          mutation: ARCHIVE_DEVICE,
          entity: data.device,
          refetchQueries: ['getDevice'],
        }, {
          component: LinkDeviceButton,
          device: data.device,
          refetch,
        }, {
          component: GetReportButton,
          reportOn: { device: data.device.id },
        }, {
          text: 'Edit device',
          color: 'teal',
          onClick: () => setEditDevicetModalOpen(true),
        }],
      }}

      surface={
        <React.Fragment>
          { linkWarning }
          {
            editDeviceModalOpen ? (
              <EditDeviceModal
                isOpen={editDeviceModalOpen}
                setOpen={setEditDevicetModalOpen}
                deviceId={deviceId}
                device={data.device}
              />
            ) : null
          }
          { archivedMessage }

          <Tab
            menu={{ secondary: true, pointing: true, style: { borderBottomWidth: '1px', margin: '20px 0 0' } }}
            panes={panes}
          />
        </React.Fragment>
      }
    />
  );
};

export default withRouter(Device);
