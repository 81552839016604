import React, { useState } from 'react';
import { Message, Button, Form } from 'semantic-ui-react';
import { Braintree, HostedField } from 'react-braintree-fields';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import { GET_CLIENT_TOKEN } from '../../../graphql/queries/getClientToken';
import { SET_PAYMENT_METHOD } from '../../../graphql/mutations/setPaymentMethod';

export const SetPaymentMethod = ({ updatePaymentInfo, setUpdatePaymentInfo }) => {
  const client = useApolloClient();
  const [braintreeReady, setBraintreeReady] = useState(false);
  const [mutationLoading, setMutationLoading] = useState(false);
  const [mutationError, setMutationError] = useState(null);
  const [tokenize, setTokenize] = useState();

  const { data = {}, loading, error } = useQuery(GET_CLIENT_TOKEN, {
    fetchPolicy: 'network-only',
  });

  function onAuthorizationSuccess() {
    setBraintreeReady(true);
  }

  async function onSubmit() {
    try {
      setMutationLoading(true);

      const { nonce } = await tokenize();

      await client.mutate({
        mutation: SET_PAYMENT_METHOD,
        variables: {
          data: { paymentMethodNonce: nonce },
        },
        refetchQueries: ['getPaymentMethods'],
      });

      setUpdatePaymentInfo(false);
    } catch (e) {
      setMutationLoading(false);
      setMutationError(e.message);
    }
  }

  const dataHandlingWarningMessage = (
    <Message success>
      <Message.Header>Your payment information is secure!</Message.Header>
      <p>We do not store your payment information on our servers. <br /> 1Joule uses Braintree for storing and processing your payment information.</p>
    </Message>
  );

  const errorMessage = mutationError ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ mutationError }</p>
    </Message>
  ) : null;

  const queryErrorMessage = error ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ error.graphQLErrors ? error.graphQLErrors[0].message : 'Error loading from remote service. Try again later' }</p>
    </Message>
  ) : null;

  return (
    <>
      { !queryErrorMessage ? dataHandlingWarningMessage : null }
      { errorMessage }
      { queryErrorMessage }
      <Braintree
        className={ braintreeReady ? '' : 'disabled' }
        authorization={data.clientToken}
        onAuthorizationSuccess={onAuthorizationSuccess}
        getTokenRef={(t) => setTokenize(() => t)}
        styles={{
          'input': {
            'font-size': '14px',
            'font-family': 'lato, helvetica, tahoma, calibri, sans-serif',
            'color': '#3a3a3a'
          },
          ':focus': {
            'color': 'black'
          }
        }}
    >
        <div className="fields">
          <Form
            loading={!braintreeReady || loading}
          >
            <Form.Field
              required
              type='number'
              label='Card number'
              placeholder='Card number'
              control={HostedField}
            />
            <Form.Field
              required
              type='expirationDate'
              label='Expiration date'
              placeholder='MM/DDDD'
              control={HostedField}
            />
            <Form.Field
              required
              type='cvv'
              label='CVV'
              placeholder='CVV'
              control={HostedField}
            />
            <Button
              color='teal'
              icon='save'
              content='Save payment method'
              loading={mutationLoading || !braintreeReady}
              disabled={mutationLoading || !braintreeReady}
              onClick={onSubmit}
            />

            {
              updatePaymentInfo && (
                <Button
                  content='Cancel'
                  loading={mutationLoading}
                  disabled={mutationLoading}
                  onClick={() => setUpdatePaymentInfo(false)}
                />
              )
            }
          </Form>
        </div>
      </Braintree>
    </>
  );
};
