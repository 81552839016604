import gql from 'graphql-tag';

export const ARCHIVE_WORKER = gql`
  mutation (
    $entity: EntityArchiveInput!
  ) {
    archiveWorker (
      entity: $entity,
    )
  }
`;
