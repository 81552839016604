import gql from 'graphql-tag';

export const REMOVE_ACCOUNT = gql`
  mutation removeAccount (
    $accountId: Float!,
  ) {
    removeAccount (
      accountId: $accountId,
    )
  }
`;
