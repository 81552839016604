import React, { useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import { AppContext } from './contexts/AppContext';
import PrivateRoute from './components/PrivateRoute';

import Accounts from './pages/Accounts';
import Payments from './pages/Payments';
import Home from './pages/Home';
import Join from './pages/Join';
import Projects from './pages/Projects';
import Project from './pages/Project';
import Workers from './pages/Workers';
import Worker from './pages/Worker';
import Works from './pages/Works';
import Work from './pages/Work';
import Devices from './pages/Devices';
import Device from './pages/Device';
import Reports from './pages/Reports';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

const App = ({ mobile }) => {
  const { setMobile } = useContext(AppContext);

  useEffect(() => {
    setMobile(mobile);
  }, [mobile, setMobile]);

  return (
    <React.Fragment>
      <Switch>
        <Route path="/" exact component={Home} />
        <PrivateRoute path="/projects" exact component={Projects} />
        <PrivateRoute path='/projects/:projectId' component={Project} />
        <PrivateRoute path="/workers" exact component={Workers} />
        <PrivateRoute path="/workers/:workerId" component={Worker} />
        <PrivateRoute path="/works" exact component={Works} />
        <PrivateRoute path="/works/:workId" exact component={Work} />
        <PrivateRoute path="/devices" exact component={Devices} />
        <PrivateRoute path='/devices/:deviceId' component={Device} />
        <PrivateRoute path="/reporting" exact component={Reports} />
        <PrivateRoute path="/accounts" exact component={Accounts} />
        <PrivateRoute path="/payments" exact component={Payments} />
        <Route path="/signin" exact component={SignIn} />
        <Route path="/signup" exact component={SignUp} />
        <Route path="/join/:invitationJWT" exact component={Join} />
        <Route path="/forgot" exact component={ForgotPassword} />
        <Route path="/reset/:resetPasswordCode" exact component={ResetPassword} />
      </Switch>
    </React.Fragment>
  );
};

export default App;
