import React, { useState } from 'react';
import { Message, Button, Form, Modal } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';

import { PaymentMethodDropdown } from '../components/PaymentMethodDropdown';
import CREATE_WORKER from '../graphql/mutations/createWorker';
import { parseErrors } from '../libs/errors';

const WORKER_PRICE = 5;

const CreateWorkertModal = ({ isOpen, setOpen, eligibleForFreeWorker, quota }) => {
  const close = () => setOpen(false);

  const [formData, setFormData] = useState({
    name: '',
    code: '',
    phoneNumber: '',
  });

  const [loading, setLoading] = useState(false);
  const [mutationLoading, setMutationLoading] = useState(false);
  const [mutationErrors, setMutationErrors] = useState(null);
  const [error, setError] = useState(false);

  const [createWorkerMutation] = useMutation(CREATE_WORKER, {
    variables: {
      entity: formData,
    },
    refetchQueries: ['getWorkers', 'getQuota'],
  });

  const firstPurchase = quota ? quota.quota - quota.used === 0 : false;

  async function createWorker() {
    try {
      setMutationLoading(true);
      await createWorkerMutation();

      close();
    } catch (e) {
      setMutationLoading(false);

      const errors = parseErrors(e);
      setMutationErrors(errors);
    }
  }

  function handleChange(e, { name, value, checked }) {
    setFormData({
      ...formData,
      [name]: checked !== undefined ? checked : value,
    });
  }

    const chargeWarningMessage = !eligibleForFreeWorker ? (
    <Message warning>
      <Message.Header>Your monthly bill will be increased by { firstPurchase ? WORKER_PRICE * (quota.quota + 1) : WORKER_PRICE } EUR.</Message.Header>
      <Message.Content>
        {
          firstPurchase
            ? `You will be charged for this worker and ${quota.quota} previously free workers.`
            : 'You will be charged for this worker.'
        }
      </Message.Content>
    </Message>
  ) : null;

  const errorMessage = mutationErrors && mutationErrors.general ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ mutationErrors.general }</p>
    </Message>
  ) : null;

  return (
    <Modal
      size='tiny'
      dimmer='blurring'
      open={isOpen}
      onClose={close}
    >
      <Modal.Header>Add new worker</Modal.Header>
      <Modal.Content>
        { chargeWarningMessage }
        { errorMessage }
        <Form
          size='small'
          autoComplete='off'
          error={!!error}
          id='modal-form'
        >
          <Form.Input
            fluid
            required
            name='name'
            label='Name'
            value={formData.name}
            error={mutationErrors ? mutationErrors.name : null}
            onChange={handleChange}
          />

          <Form.Input
            fluid
            name='code'
            label='Code'
            value={formData.code}
            error={mutationErrors ? mutationErrors.code : null}
            onChange={handleChange}
          />

          <Form.Input
            fluid
            name='phoneNumber'
            label='Phone number'
            value={formData.phoneNumber}
            error={mutationErrors ? mutationErrors.phoneNumber : null}
            onChange={handleChange}
          />

          {
            !eligibleForFreeWorker ? (
              <PaymentMethodDropdown
                setError={setError}
                setLoading={setLoading}
              />
            ) : null
          }
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content='Cancel'
          onClick={close}
        />
        <Button
          form='modal-form'
          color='teal'
          icon='plus'
          content={eligibleForFreeWorker ? 'Create' : 'Purchase'}
          loading={mutationLoading || loading}
          disabled={mutationLoading || loading}
          onClick={createWorker}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default CreateWorkertModal;
