import React, { useState, useEffect } from 'react';
import { Dimmer, Loader, Form, Message } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { parseErrors } from '../../libs/errors';
import ACCEPT_INVITATION_WITH_PASSWORD from '../../graphql/mutations/acceptInvitationWithPassword';
import ACCEPT_INVITATION from '../../graphql/mutations/acceptInvitation';
import VERIFY_INVITATION from '../../graphql/mutations/verifyInvitation';
import { AccountLayout } from '../../layouts/AccountLayout';

function getHeaderText({ organization, invitationAccepted, organizationCreation } = {}) {
  const { name } = organization;

  if (invitationAccepted) {
    return null;
  }

  return organizationCreation
    ? `Create ${name} organization`
    : `Join ${name} organization`;
}

function getHeaderDescription({ organization, invitationAccepted, organizationCreation, accountPasswordSet } = {}) {
  const { name } = organization;

  if (invitationAccepted) {
    return null;
  }

  if (organizationCreation) {
    if (accountPasswordSet) {
      return 'Since your email is already signed up, you need to simply click continue.';
    } else {
      return 'Set up a password for your account.'
    }
  } else {
    if (accountPasswordSet) {
      return (
        <React.Fragment>
          You were invited to join <b>{name}</b> organization.<br/>
          Since your email is already signed up, you need to simply accept the invitation.
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          You were invited to join <b>{name}</b> organization.
          Since you don't have an account yet, you need to set up a password.
        </React.Fragment>
      );
    }
  }
}

const Join = ({ match }) => {
  const { invitationJWT } = match.params;

  const [formData, setFormData] = useState({
    invitationJWT,
    password: '',
  });
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [verificationData, setVerificationData] = useState(null);
  const [mutationErrors, setMutationErrors] = useState(null);

  const [verifyInvitationMutation] = useMutation(VERIFY_INVITATION, {
    variables: {
      data: { invitationJWT },
    },
  });

  const [acceptInvitationWithPasswordMutation] = useMutation(ACCEPT_INVITATION_WITH_PASSWORD, {
    variables: {
      data: formData,
    },
  });

  const [acceptInvitationMutation] = useMutation(ACCEPT_INVITATION, {
    variables: {
      data: { invitationJWT },
    },
  });

  useEffect(() => {
    async function verifyInvitation() {
      try {
        const { data } = await verifyInvitationMutation();

        setVerificationData(data.verifyInvitation);
        setLoading(false);
      } catch (e) {
        setLoading(false);

        const errors = parseErrors(e);
        setMutationErrors(errors);
      }
    }

    if (!verificationData && !mutationErrors) {
      verifyInvitation();
    }
  }, [verificationData, loading, mutationErrors, verifyInvitationMutation]);

  async function acceptInvitation() {
    try {
      if (verificationData.accountPasswordSet) {
        await acceptInvitationMutation();
      } else {
        await acceptInvitationWithPasswordMutation();
      }

      setSuccess(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      const errors = parseErrors(e);
      setMutationErrors(errors);
    }
  }

  function handleChange(e, { name, value }) {
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const successMessage = success ? (
    <Message positive>
      <Message.Header>Success!</Message.Header>
      You can sign in with your email now. <Link to='/signin'>Sign in</Link>
    </Message>
  ) : null;

  const alreadyAcceptedMessage = verificationData && verificationData.invitationAccepted ? (
    <Message positive>
      <Message.Header>Success!</Message.Header>
      You can sign in with your email now. <Link to='/signin'>Sign in</Link>
    </Message>
  ) : null;

  if (loading) {
    return (
      <Dimmer inverted active >
        <Loader inverted content='Loading' />
      </Dimmer>
    );
  }

  if (mutationErrors && mutationErrors.general) {
    return (
      <AccountLayout>
        <Message negative>
          <p>{ mutationErrors.general }</p>
        </Message>
      </AccountLayout>
    );
  }

  return (
    <AccountLayout
      headerText={verificationData && getHeaderText(verificationData)}
      headerDescription={verificationData && getHeaderDescription(verificationData)}
    >
        { successMessage }
        { alreadyAcceptedMessage }

        { !success ? (
          <Form
            size='small'
            noValidate
            loading={loading}
          >
            {
              verificationData && !verificationData.accountPasswordSet ? (
                <Form.Input
                  fluid
                  required
                  name='password'
                  type='password'
                  icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  value={formData.password}
                  error={mutationErrors ? mutationErrors.password : null}
                  onChange={handleChange}
                />
              ) : null
            }

            {
              verificationData && !verificationData.invitationAccepted ? (
                <Form.Button
                  fluid
                  content='Continue'
                  color='teal'
                  size='small'
                  onClick={acceptInvitation}
                />
              ) : null
            }
          </Form>
        ) : null }
    </AccountLayout>
  );
};

export default withRouter(Join);
