import gql from 'graphql-tag';

const GET_PROJECT = gql`
  query getProject (
    $projectId: Float!
  ) {
    project (projectId: $projectId){
      id
      name
      code
      status
      start
      end
      budget

      createdAt

      works {
        id
        name
      }

      workers {
        id
        name
      }

      devices {
        id
        name
      }
    }
  }
`;

export default GET_PROJECT;
