import gql from 'graphql-tag';

const VERIFY_INVITATION = gql`
  mutation verifyInvitation (
    $data: VerifyInvitationInput!
  ) {
    verifyInvitation (
      data: $data
    ) {
      organization {
        name
      }
      organizationCreation
      invitationAccepted
      accountPasswordSet
    }
  }
`;

export default VERIFY_INVITATION;
