import React, { useContext } from 'react';
import { Image, Button, Container, Menu, } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import LogoSmall from '../../../assets/logo_small.png';
import { AppContext } from '../../../contexts/AppContext';

const LandingUpperMenu = () => {
  const { mobile } = useContext(AppContext);

  // TODO: move all token operations to separate util module
  const token = localStorage.getItem('token');
  const isSignedIn = token !== null;

  return (
    <Menu
      borderless
      size='large'
      style={styles.menu}
    >
      <Container>
        <Menu.Item header>
          <Image
            size='mini'
            src={LogoSmall}
          />
        </Menu.Item>
        <Menu.Item position='right'>
          <Button.Group>
            {
              isSignedIn ? (
                <Button as={Link} to='/projects' color='teal' size='small'>
                  Go to console
                </Button>
              ) : (
                <React.Fragment>
                  <Button as={Link} to='/signup' color='teal' size='small'>
                    Try now
                  </Button>
                  <Button as={Link} to='/signin'>
                    Log in
                  </Button>
                </React.Fragment>
              )
            }
          </Button.Group>
        </Menu.Item>
      </Container>
    </Menu>
  );
};

const styles = {
  menu: {
    boxShadow: 'none',
    border: 'none',
  },
};

export default LandingUpperMenu;
