import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export function assignedProjectsDropdown(projects) {
  if (!projects.length) {
    return 'Not assigned';
  }

  const dropdownText = `${projects.length} project${projects.length > 1 ? 's' : ''}`;

  return (
    <Dropdown
      text={dropdownText}
      direction='left'
    >
      <Dropdown.Menu>
        {
          projects.map(project => (
            <Dropdown.Item
              as={Link}
              to={'/projects/' + project.id}
              key={project.id}
              text={project.name}
            />
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}

