import gql from 'graphql-tag';

const GET_PROJECTS = gql`
  query getProjects {
    projects {
      id
      name
      code
      start
      end
      status
      createdAt

      works {
        id
        name
      }

      workers {
        id
        name
      }

      devices {
        id
        name
      }
    }
  }
`;

export default GET_PROJECTS;
