import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import moment from 'moment';

export const WorkInfoTable = ({ work }) => {
  const tableRows = [{
    key: 'Unit',
    value: work.unit || '-',
  }, {
    key: 'Unit input required',
    value: work.requireUnitInput ? 'Yes' : 'No',
  }, {
    key: 'Created',
    value: moment(Number(work.createdAt)).calendar(),
  }, {
    key: 'Updated',
    value: work.updatedAt ? moment(Number(work.updatedAt)).calendar() : '-',
  }].map(({ key, value }) => (
    <Table.Row key={key}>
      <Table.Cell>{ key }</Table.Cell>
      <Table.Cell textAlign='right'>{ value }</Table.Cell>
    </Table.Row>
  ));

  return (
    <Table unstackable basic>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell colSpan='2'><Icon name='tasks' /> Work</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{ tableRows }</Table.Body>
    </Table>
  );
};
