import React from 'react'
import { Header, Segment, Grid, Icon } from 'semantic-ui-react';

const FEATURES = [{
  icon: 'mobile',
  text: 'App for your employees',
}, {
  icon: 'mobile alternate',
  text: 'Terminal app for your facility',
}, {
  icon: 'chart bar',
  text: 'Real time insights',
}, {
  icon: 'check circle',
  text: 'Activity approvals',
}, {
  icon: 'file excel',
  text: 'Flexible reporting',
}, {
  icon: 'eur',
  text: 'Projects budgeting',
}];

export const FeaturesSegment = () => {
  return (
    <Segment
      basic
      vertical
      textAlign='center'
      style={{ padding: '8em 0em' }}
    >
      <Header as='h3' style={{ fontSize: '2em' }}>
        Save time with these key features:
      </Header>
      <Grid
        stackable
        centered
        container
        doubling
        columns={3}
      >
        {
          FEATURES.map((feature) => (
            <Grid.Column style={styles.column}>
              <Icon
                name={feature.icon}
                size='large'
                color='teal'
                style={styles.icon}
              />
              <p>{feature.text}</p>
            </Grid.Column>
          ))
        }
      </Grid>
    </Segment>
  );
}

const styles = {
  column: {
    textAlign: 'center',
    maxWidth: 300,
  },
  icon: {
    margin: 20,
  },
};
