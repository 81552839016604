import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

export function todayValueOf() {
  return moment().startOf('day').valueOf();
}

export function getDurationInHours(start, end) {
  if (!end) {
    return 0;
  }

  const duration = moment.duration(moment(Number(end)).diff(Number(start))).asHours();

  return Math.abs(duration);
}

export function formatDuration(duration) {
  return moment.duration(duration, 'hours').format('h[h] mm[m]');
}

export function sumEntriesByWork(entries) {
  return entries.reduce((acc, entry) => {
    const workId = entry.work.id;
    const duration = getDurationInHours(entry.start, entry.end);

    if (acc[workId]) {
      acc[workId] += duration;
    } else {
      acc[workId] = duration;
    }

    return acc;
  }, {});
}

export function sortEntries(entries) {
  return entries.sort((a, b) => a.start - b.start);
}

export function getExtemumEntries(sortedEntries) {
  const earliestEntry = sortedEntries[0];
  const latestEntry = sortedEntries[sortedEntries.length - 1];

  return { 
    earliestEntry,
    latestEntry,
  };
}

export function getMinX(earliestEntry) {
  return moment(Number(earliestEntry.start)).subtract(1, 'day').startOf('day').valueOf();
}

export function getMaxX(projectEnd, latestEntry) {
  return moment(Number(projectEnd)).isAfter(Number(latestEntry.start))
    ? moment(Number(projectEnd)).add(1, 'day').startOf('day').valueOf()
    : moment(Number(latestEntry.start)).add(1, 'day').startOf('day').valueOf()
}

export function getMaxY(predictedValue, budget) {
  return Math.round((predictedValue> budget ? predictedValue : budget) * 1.1);
}

export function getTotalSpentTime(progressSeriesData) {
  return progressSeriesData[progressSeriesData.length - 1][1];
}

export function getTotalSpentTimeFirstDay(progressSeriesData) {
  return progressSeriesData[0][1];
}
