import gql from 'graphql-tag';

export const GET_DEVICE = gql`
  query getDevice (
    $deviceId: Float!
  ) {
    device (deviceId: $deviceId){
      id
      name
      archived
      createdAt

      session {
        createdAt
      }

      projects {
        id
        name
      }
    }
  }
`;
