import gql from 'graphql-tag';

export const SET_ENTRIES_PENDING = gql`
  mutation (
    $data: EntriesApproveInput!
  ) {
    setEntriesPending (
      data: $data,
    )
  }
`;
