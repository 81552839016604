import React, { useContext, useEffect, useState } from 'react';
import { Popup, Icon, Menu, Sidebar } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { AppContext } from '../contexts/AppContext';
import { RightMenuContext } from '../contexts/RightMenuContext';

const ExpandButton = () => {
  const { rightMenuOpen, setRightMenuOpen } = useContext(RightMenuContext);

  return (
    <Menu
      icon
      fluid
      vertical
      style={styles.menu}
    >
      <Menu.Item
        icon={true}
        style={styles.expandButtonMenuItem}
        onClick={() => setRightMenuOpen(!rightMenuOpen)}
      >
        <Icon
          style={styles.expandButtonMenuItemIcon}
          name={ rightMenuOpen ? 'angle double right' : 'angle double left' }
        />
      </Menu.Item>
    </Menu>
  );
}

export const CollapsedElements = ({ items }) => {
  const menuItems = items.map((item) => {
    return (
      <Popup
        position='left center'
        key={item.name}
        content={item.popup}
        trigger={
          <Menu.Item
            style={styles.collapsedMenuItem}
            name={item.name}
            onClick={item.onClick}
          >
            <Icon name={item.icon} />
            { item.text }
          </Menu.Item>
        }
      />
    );
  });

  return (
    <Menu
      borderless
      fluid
      vertical
      icon='labeled'
      size='mini'
      style={styles.menu}
    >
      { menuItems }
    </Menu>
  );
}

export const RightMenu = withRouter(({ children, location }) => {
  const { mobile } = useContext(AppContext);
  const { rightMenuOpen, setRightMenuOpen } = useContext(RightMenuContext);
  const [url, setUrl] = useState(null);

  // This effect is forcefully closes left menu, when url in browser has changed.
  // Relevant only for mobile users.
  useEffect(() => {
    if (mobile && location.pathname !== url) {
      setRightMenuOpen(false);
    }

    setUrl(location.pathname);
  }, [mobile, url, setUrl, location, setRightMenuOpen]);

  return (
    <Sidebar
      style={{...styles.sidebar, ...(rightMenuOpen ? styles.sidebarOpen : styles.sidebarClosed)}}
      direction='right'
      visible={mobile ? rightMenuOpen : true}
    >
      <ExpandButton />
      { children }
    </Sidebar>
  );
});

const styles = {
  sidebar: {
    boxShadow: 'none',
    backgroundColor: '#f8f8f8',
    borderLeft: '1px solid rgba(34,36,38,.15)',
    top: '60px',
    zIndex: 100,
  },
  sidebarOpen: {
    width: '300px',
  },
  sidebarClosed: {
    width: '60px',
  },

  menu: {
    border: 0,
    borderRadius: 0,
    boxShadow: 'none',
    margin: 0,
    backgroundColor: '#f8f8f8',
  },
  expandButtonMenuItem: {
    display: 'flex',
    height: '60px',
  },
  expandButtonMenuItemIcon: {
    alignSelf: 'center',
  },
};
