import moment from 'moment';

import { getDurationInHours } from './common';

export function calculateProgressSeriesData(entries, minX) {
  const groupedEntriesByStart = entries.reduce((acc, entry) => {
    if (!entry.end) {
      return acc;
    }

    const startOfDay = moment(Number(entry.start)).startOf('day').valueOf()
    const sameStartEntry = acc[startOfDay];
    const duration = getDurationInHours(entry.start, entry.end)

    if (sameStartEntry) {
      acc[startOfDay] += duration;
    } else {
      acc[startOfDay] = duration;
    }

    return acc;
  }, {});

  const data = [];

  let day = moment(Number(minX)).startOf('day');
  while (day <= moment().startOf('day').valueOf()) {
    const dayValueOf = day.valueOf();
    const lastData = data[data.length - 1];
    const duration = groupedEntriesByStart[dayValueOf] ? groupedEntriesByStart[dayValueOf] : 0;

    const newDuration = lastData ? lastData[1] + duration : duration;

    data.push([dayValueOf, newDuration]);

    day = day.clone().add(1, 'd');
  }

  return data;
}

