import moment from 'moment';
import React, { useContext } from 'react';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';

import { todayValueOf, formatDuration } from '../../utils/common';
import { AppContext } from '../../../../contexts/AppContext';
import { ProjectContext } from '../../contexts/ProjectContext';
import { Disclaimer } from '../../../../components/Disclaimer';

const TOTAL_TIME_CHART_NAME = 'Total time';
const PREDICTION_CHART_NAME = 'Prediction';

const STATUS_TO_COLOR = {
  pending: '#767676',
  active: '#8abc1e',
  completed: '#10a3a3',
};

function makeTodayLine(maxY) {
  return {
    type: 'line',
    markLine : {
      symbol: 'none',
      label: {
        formatter: 'Today',
      },
      lineStyle: {
        normal: {
          type: 'dotted',
          color: 'red',
        }
      },
      data : [
        [{
          xAxis: todayValueOf(),
          yAxis: 0,
        },{
          xAxis: todayValueOf(),
          yAxis: maxY,
        }],
      ]
    }
  };
}

function makeBudgetLine(minX, maxX, budget) {
  return {
    type: 'line',
    markLine : {
      symbol: 'none',
      label: {
        position: 'middle',
        formatter: 'Budget',
      },
      lineStyle: {
        normal: {
          type: 'solid',
          color: 'red',
        }
      },
      data : [
        [{
          xAxis: minX,
          yAxis: budget,
        },{
          xAxis: maxX,
          yAxis: budget,
        }],
      ]
    }
  };
}

function makePredictionChart(totalSpentTime, maxX, predictedValue) {
  return {
    name: PREDICTION_CHART_NAME,
    type: 'line',
    areaStyle: {
      color: 'grey',
      opacity: 0.25,
    },
    lineStyle: {
      color: 'grey',
      type: 'dotted',
    },
    itemStyle: {
      color: 'grey',
    },
    data: [
      [
        todayValueOf(),
        totalSpentTime,
      ],
      [
        maxX,
        predictedValue,
      ],
    ],
  }
}

function makeTotalTimeChart(totalTimeSeriesData, color) {
  return {
    name: TOTAL_TIME_CHART_NAME,
    type: 'line',
    smooth: false,
    symbol: 'none',
    itemStyle: {
      color,
    },
    areaStyle: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
        offset: 0,
        color: `${color}b8`,
      }, {
        offset: 1,
        color: `${color}3a`,
      }]),
    },
    data: totalTimeSeriesData,
  };
}

export const ProgressLineChart = () => {
  const { mobile } = useContext(AppContext);
  const { projectData, entriesData, chartReady, budget, progressSeriesData, totalSpentTime, predictedValue, boundaries } = useContext(ProjectContext)
  const { minX, maxX, maxY } = boundaries;

  if (!entriesData.entries || !entriesData.entries.length || !chartReady) {
    return (
      <Disclaimer
        icon='exclamation triangle'
        content='No data available yet'
      />
    );
  }

  const option = {
    tooltip : {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      },
      formatter: (items) => {
        return items
          .filter(({ seriesName }) => !(seriesName === 'Prediction' && items.length > 1))
          .map(({ marker, seriesName, value }) => {
            const duration = formatDuration(value[1]);

            return `${marker} ${seriesName}: ${duration}`;
          })
          .join('<br/>');
      }
    },
    legend: !mobile ? {
      data: [
        TOTAL_TIME_CHART_NAME,
        PREDICTION_CHART_NAME,
      ],
    } : null,
    grid: {
      left: mobile ? '0' : '2%',
      right: mobile ? '0' : '2%',
      bottom: mobile ? '15%' : '5%',
      containLabel: true,
    },
    xAxis: [{
      type: 'time',
      boundaryGap: false,
      axisLabel: {
        formatter: (value) => moment(value).format('DD.MM'),
      },
      axisPointer: {
        value: todayValueOf(),
        snap: true,
        lineStyle: {
          color: '#004E52',
          opacity: 0.5,
          width: 2,
        },
        label: {
          formatter: (value) => moment(value.value).format('DD.MM.YYYY'),
        },
        handle: {
          show: mobile,
          color: '#004E52',
        },
      },
      min: minX,
      max: maxX
    }],
    yAxis: [{
      type: 'value',
      min: 0,
      max: maxY,
      axisLine: {
        show: !mobile,
      },
      axisLabel: {
        show: !mobile,
      }
    }],
    series: [
      makeTodayLine(maxY),
      makeTotalTimeChart(progressSeriesData, STATUS_TO_COLOR[projectData.project.status]),
    ],
  };

  if (projectData.project.end) {
    const predictionChart = makePredictionChart(totalSpentTime, maxX, predictedValue);

    option.series.push(predictionChart);
  }

  if (budget) {
    const budgetLine = makeBudgetLine(minX, maxX, budget);

    option.series.push(budgetLine);
  }

  return (
    <ReactEcharts
      option={option}
      style={styles.chart}
    />
  );
};

const styles = {
  chart: {
    height: '400px',
    marginBottom: '30px',
  },
};
