import React, { useState, useContext } from 'react';
import { Button, Confirm, Message, Icon, Header, Modal, Popup } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';

import { AppContext } from '../contexts/AppContext';
import { parseErrors } from '../libs/errors';

export const ArchiveButton = ({ disclaimer, mutation, entity, refetchQueries, mobile, ...rest }) => {
  const { setError } = useContext(AppContext);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [mutationLoading, setMutationLoading] = useState(false);
  const [archived, setArchived] = useState(entity.archived);

  const [archiveMutation] = useMutation(mutation, {
    variables: {
      entity: {
        id: Number(entity.id),
        archived: true
      },
    },
    refetchQueries: [...refetchQueries],
  });

  const [unarchiveMutation] = useMutation(mutation, {
    variables: {
      entity: {
        id: Number(entity.id),
        archived: false,
      },
    },
    refetchQueries: [...refetchQueries],
  });

  async function archiveEntity() {
    try {
      setMutationLoading(true);

      if (archived) {
        await unarchiveMutation();
      } else {
        await archiveMutation();
      }

      setArchived(!archived);
      setMutationLoading(false);
    } catch (e) {
      setMutationLoading(false);
      setArchived(entity.archived);

      const errors = parseErrors(e);
      setError(errors);
    }
  }

  async function handleClick() {
    setConfirmOpen(false);

    if (!mutationLoading) {
      await archiveEntity();
    }
  }

  function renderConfirmHeader() {
    return (
      <>
        {
          disclaimer && ( 
            <Message style={styles.disclaimer}>
              <Message.Header>
                <Icon name='warning sign' style={styles.disclaimerIcon} />
                { disclaimer }
              </Message.Header>
            </Message>
          )
        }
        <Header
          icon='archive'
          content={
            archived
              ? `Unarchive ${entity.name}?`
              : `Archive ${entity.name}?`
          }
        />
      </>
    );
  };

  function confirmationText() {
    return (
      <Modal.Content>
        {
          archived
            ? `When unarchived "${entity.name}" will be visible in projects and devices.`
            : `After archivation any activities associated with "${entity.name}" will be forcibly stopped.`
        }
        <br/>
        <br/>
        Continue?
      </Modal.Content>
    );
  }

  function renderConfirmButton() {
    return (
      <Button
        basic
        inverted
        color='teal'
        icon='checkmark'
        content='Yes'
        onClick={handleClick}
      />
    );
  }

  function renderCancelButton() {
    return (
      <Button
        basic
        inverted
        color='red'
        icon='remove'
        content='No'
        onClick={() => setConfirmOpen(false)}
      />
    );
  }

  const buttonContent = archived ? 'Unarchive' : 'Archive';
  const button = (
    <Button
      basic
      fluid={mobile}
      content={mobile ? buttonContent : null}
      color={archived ? 'grey' : 'red'}
      icon={!mobile ? 'archive' : null}
      loading={mutationLoading}
      disabled={mutationLoading}
      onClick={() => setConfirmOpen(true)}
    />
  );

  return (
    <React.Fragment>
      {
        mobile ? (
          button
        ) : (
          <Popup
            inverted
            size='small'
            content={buttonContent}
            trigger={button}
          />
        )
      }

      <Confirm
        basic
        size='tiny'
        content={confirmationText}
        header={renderConfirmHeader}
        confirmButton={renderConfirmButton}
        cancelButton={renderCancelButton}
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
      />
    </React.Fragment>
  );
}

const styles = {
  disclaimer: {
    textAlign: 'center',
    backgroundColor: 'transparent',
    border: '2px solid #ff851b',
    color: '#ff851b',
    marginBottom: '25px',
  },

  disclaimerIcon: {
    marginRight: '8px',
  },
};
