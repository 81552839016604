import React, { useEffect, useContext } from 'react';
import { Table, Message, Form, Container, Segment, Grid, Header, Button } from 'semantic-ui-react';

import { AppContext } from '../contexts/AppContext';
import { RightMenuContext } from '../contexts/RightMenuContext';
import { LeftMenuContext } from '../contexts/LeftMenuContext';
import { UpperMenu } from '../containers/UpperMenu';
import { LeftMenu } from '../containers/LeftMenu';
import { RightMenu } from '../containers/RightMenu';

const RightMenuToggleButton = () => {
  const { rightMenuOpen, rightMenuExists, setRightMenuOpen } = useContext(RightMenuContext);

  return rightMenuExists ? (
    <Button
      floated='right'
      icon='angle double left'
      onClick={() => setRightMenuOpen(!rightMenuOpen)}
    />
  ) : null;
};

export const PageHeader = ({ header, size='large' } = {}) => {
  const { mobile } = useContext(AppContext);

  const headerButtons = header.buttons ? header.buttons.map((button, i) => {
    const { component, ...rest } = button;

    if (component) {
      const Component = component;
      return <Component
        key={i}
        mobile={mobile}
        style={{
          ...(mobile ? styles.headerButtonMobile : styles.headerButton)
        }}
        { ...rest }
      />
    }

    return (
      <Button
        basic
        icon={button.icon ? button.icon : null}
        fluid={mobile}
        key={button.text}
        content={button.text}
        loading={button.loading ? button.loading : false}
        disabled={button.disabled ? button.disabled : false}
        color={button.color || 'grey'}
        style={{
          ...(mobile ? styles.headerButtonMobile : styles.headerButton)
        }}
        onClick={button.onClick}
      />
    );
  }) : null;

  return mobile ? (
    <Container>
      <Table
        fixed
        singleLine
        unstackable
        basic='very'
        style={{ margin: 0 }}
      >
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Header as='h2' size={size}>
                <Header.Content>
                  {header.text}
                  <Header.Subheader>{header.subheader}</Header.Subheader>
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell width={3} textAlign='right'>
              <RightMenuToggleButton />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Form>
        { headerButtons }
      </Form>
    </Container>
  ) : (
    <Container>
      <Grid
        verticalAlign='middle'
        columns={2}
        padded='vertically'
        style={styles.headerGrid}
      >
        <Grid.Column style={styles.column} width={6}>
          <Header as='h2' size={size}>
            <Header.Content>
              {header.text}
              <Header.Subheader>{header.subheader}</Header.Subheader>
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column style={styles.column} width={10}>
          <Form
            style={{ float: 'right' }}
          >
            { headerButtons }
          </Form>
        </Grid.Column>
      </Grid>
    </Container>
  );
}

const PageError = () => {
  const { error, setError, mobile } = useContext(AppContext);

  function handleDismiss() {
    setError(null);
  }

  return error && (
    <Segment
      basic
      style={{
        ...(mobile ? styles.headerMobile : null),
        ...styles.header,
      }}
    >
      <Container>
        <Message
          negative
          onDismiss={handleDismiss}
        >
          <Message.Header>Error occured.</Message.Header>
          <Message.Content>{ error.general }</Message.Content>
        </Message>
      </Container>
    </Segment>
  );
}

const CommonLayout = ({ header, surface, rightMenu }) => {
  const { mobile } = useContext(AppContext);
  const { rightMenuOpen, setRightMenuExists } = useContext(RightMenuContext);
  const { leftMenuOpen } = useContext(LeftMenuContext);

  let marginRight = 0;
  let marginLeft = 0;

  if (!mobile) {
    marginRight = rightMenu ? rightMenuOpen ? '300px' : '60px' : 0;
    marginLeft = leftMenuOpen ? '200px' : '60px';
  }

  useEffect(() => {
    setRightMenuExists(!!rightMenu);
  }, [rightMenu, setRightMenuExists]);

  return (
    <React.Fragment>
      <UpperMenu />
      <LeftMenu />

      <Segment
        basic
        style={{
          ...styles.content,
          marginRight,
          marginLeft,
        }}
      >
        <PageError />

        <Segment
          basic
          style={{
            ...(mobile ? styles.headerMobile : null),
            ...styles.header,
          }}
        >
          <PageHeader header={header} />
        </Segment>

        <Segment
          basic
          style={{
            ...(mobile ? styles.surfaceMobile : null),
            ...styles.surface,
          }}
        >
          <Container>
            { surface }
          </Container>
        </Segment>
      </Segment>

    {
      rightMenu ? (
        <RightMenu>
          { rightMenu }
        </RightMenu>
      ) : null
    }
    </React.Fragment>
  );
}

const styles = {
  content: {
    margin: 0,
    padding: 0,
    paddingTop: '60px',
    paddingBottom: '30px',
  },
  header: {
    margin: 0,
    paddingBottom: '10px',
  },
  headerMobile: {
    paddingTop: '15px',
    paddingLeft: 0,
    paddingRight: 0,
  },
  headerGrid: {
    flexGrow: 1,
    height: '50px',
  },
  column: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  surface: {
    margin: 0,
    paddingTop: 0,
  },
  surfaceMobile: {
    padding: 0,
  },
  headerButton: {
    marginLeft: '10px',
    marginRight: 0,
  },
  headerButtonMobile: {
    marginTop: '10px',
  },
};

export default CommonLayout;
