import React from 'react';
import { Icon } from 'semantic-ui-react';

export const Exclamation = ({ title, text }) => {
  return (
    <React.Fragment>
      <Icon title={title} name='warning' color='red' />
      { text }
    </React.Fragment>
  );
}
