import gql from 'graphql-tag';

const CREATE_WORK = gql`
  mutation (
    $entity: WorkCreateInput!
  ) {
    createWork (
      entity: $entity,
    )
  }
`;

export default CREATE_WORK;
