import gql from 'graphql-tag';

const UDPDATE_WORK = gql`
  mutation updateWork(
    $entity: WorkUpdateInput!
  ) {
    updateWork (
      entity: $entity,
    )
  }
`;

export default UDPDATE_WORK;
