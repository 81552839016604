import React, { useState } from 'react';
import { Icon, Tab, Message } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { useQuery } from '@apollo/react-hooks';

import { ArchiveButton } from '../../components/ArchiveButton';
import { GetReportButton } from '../../components/GetReportButton';
import EditWorkerModal from '../../modals/editWorkerModal';
import CommonLayout from '../../layouts/CommonLayout';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import { GET_WORKER } from '../../graphql/queries/getWorker';
import { GET_QUOTA } from '../../graphql/queries/getQuota';
import { ARCHIVE_WORKER } from '../../graphql/mutations/archiveWorker';
import { OverviewTab } from './components/OverviewTab';
import { ActivityTab } from './components/ActivityTab';

const WORKER_PRICE = 5;

const Worker = ({ match }) => {
  const { workerId } = match.params;

  const [isEditWorkerModalOpen, setEditWorkerModalOpen] = useState(false);

  const { data: workerData = {}, loading: workerLoading } = useQuery(GET_WORKER, {
    variables: {
      workerId: Number(workerId),
    },
  });

  const { data: quotaData = {}, loading: quotaLoading } = useQuery(GET_QUOTA, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        quotaType: 'FreeWorkers',
        excludeArchived: true,
      },
    },
  });

  const loading = workerLoading || quotaLoading;

  if (loading) {
    return <LoadingPlaceholder />;
  }

  const panes = [
    { menuItem: 'Overview', render: () => <OverviewTab worker={workerData.worker} /> },
    { menuItem: 'Activity', render: () => <ActivityTab worker={workerData.worker} /> },
  ];

  const archivedMessage = workerData.worker.archived ? (
    <Message warning>
      <Message.Header>Worker is archived.</Message.Header>
      <Message.Content>Archived workers will not appear in projects and devices.</Message.Content>
    </Message>
  ) : null;

  function archivationDisclaimerText() {
    const payedWorker = quotaData.quota.used > quotaData.quota.quota;
    if (!payedWorker) {
      return null;
    }

    const firstPurchase = workerData.worker.archived
      ? quotaData.quota.quota - quotaData.quota.used === 0
      : quotaData.quota.used - quotaData.quota.quota === 1;
    const changeText = workerData.worker.archived ? 'increased' : 'decreased';
    const priceText = firstPurchase
      ? WORKER_PRICE * (quotaData.quota.quota + 1)
      : WORKER_PRICE;

    return  `Your monthly bill will be ${changeText} by ${priceText} EUR.`;
  }

  return (
    <CommonLayout
      header={{
        text: workerData.worker.name,
        subheader: workerData.worker.code,
        buttons: [{
          component: ArchiveButton,
          disclaimer: archivationDisclaimerText(),
          mutation: ARCHIVE_WORKER,
          entity: workerData.worker,
          refetchQueries: ['getWorker', 'getQuota'],
        }, {
          component: GetReportButton,
          reportOn: { worker: workerData.worker.id },
        }, {
          text: 'Edit worker',
          color: 'teal',
          onClick: () => setEditWorkerModalOpen(true),
        }],
      }}

      surface={
        <React.Fragment>
          {
            isEditWorkerModalOpen ? (
              <EditWorkerModal
                isOpen={isEditWorkerModalOpen}
                setOpen={setEditWorkerModalOpen}
                workerId={workerId}
                worker={workerData.worker}
              />
            ) : null
          }
          { archivedMessage }

          <Tab
            menu={{ secondary: true, pointing: true, style: { borderBottomWidth: '1px', margin: '20px 0 0' } }}
            panes={panes}
          />
        </React.Fragment>
      }
    />
  );
};

export default withRouter(Worker);
