import React, { useContext, useEffect, useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import useWindowSize from '@rehooks/window-size';

import { RightMenuContext } from '../../../contexts/RightMenuContext';
import { WorkersTable } from './WorkersTable';
import { WorksTable } from './WorksTable';
import { DevicesTable } from './DevicesTable';

export const ExpandedSidebarElements = ({ project }) => {
  const { innerHeight } = useWindowSize();

  const { clickedItem } = useContext(RightMenuContext);
  const [activeAccordion, setActiveAccordion] = useState(null);

  useEffect(() => {
    setActiveAccordion(clickedItem);
  }, [clickedItem]);

  function handleClick(e, data) {
    const { index } = data;
    const newIndex = index === activeAccordion ? -1 : index;

    setActiveAccordion(newIndex);
  }

  const accordionItems = [{
    index: 'workers',
    icon: 'user outline',
    text: `${project.workers.length} assigned workers`,
    content: <WorkersTable workers={project.workers} />,
  }, {
    index: 'works',
    icon: 'tasks',
    text: `${project.works.length} assigned works`,
    content: (
      <WorksTable
        works={project.works}
        entries={project.entries}
      />
    ),
  }, {
    index: 'devices',
    icon: 'mobile alternate',
    text: `${project.devices.length} assigned devices`,
    content: <DevicesTable devices={project.devices} />,
  }].map(item => {
    const isActive = activeAccordion === item.index;
    const accordionContentHeight = `${innerHeight - 60 - 60 - 41 - 41 - 40}px`; //TODO: redo this

    return (
      <React.Fragment key={item.index}>
        <Accordion.Title
          active={isActive}
          index={item.index}
          onClick={handleClick}
        >
          <Icon name={item.icon} />
          { item.text }
        </Accordion.Title>
        <Accordion.Content
          active={isActive}
          style={{
            ...styles.accordionContent,
            maxHeight: accordionContentHeight,
          }}
        >
          { item.content }
        </Accordion.Content>
      </React.Fragment>
    );
  });

  return (
    <Accordion
      fluid
      styled
      style={styles.accordion}
    >
      { accordionItems }
    </Accordion>
  );
}

export const CollapsedSidebarElements = ({ project }) => {
  const { setClickedItem, setRightMenuOpen, toCollapsedElements } = useContext(RightMenuContext);

  function handleClick(e, data) {
    setClickedItem(data.name);
    setRightMenuOpen(true);
  }

  return toCollapsedElements([{
    name: 'workers',
    onClick: handleClick,
    icon: 'user outline',
    text: project.workers.length,
    popup: 'Assigned workers',
  }, {
    name: 'works',
    onClick: handleClick,
    icon: 'tasks',
    text: project.works.length,
    popup: 'Assigned works',
  }, {
    name: 'devices',
    onClick: handleClick,
    icon: 'mobile alternate',
    text: project.devices.length,
    popup: 'Assigned devices',
  }]);
}

const styles = {
  accordion: {
    borderRadius: 0,
  },
  accordionContent: {
    overflow: 'auto',
  },
};
