import gql from 'graphql-tag';

export const SET_PAYMENT_METHOD = gql`
  mutation (
    $data: PaymentMethodInput!
  ) {
    setPaymentMethod (
      data: $data,
    )
  }
`;
