import React from 'react';
import { Card, Button, Grid, Divider, Segment, Container, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export const PricingSegment = () => {
  return (
    <Segment
      basic
      vertical
      style={{ padding: '8em 0em' }}
    >
      <Container text>
        <Grid padded='vertically'>
          <Grid.Column textAlign='center'>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Easy on the wallet.
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              No credit card is required to start. First 5 workers are free.
            </p>
            <Card style={{ margin:'auto', marginTop: '25px' }}>
              <Card.Content>
                <Card.Header>More than 5 workers</Card.Header>
                <Card.Meta></Card.Meta>
                <Divider section hidden />
                <Header as='h1'>
                  5 EUR<br/><span style={{ fontSize:'0.6em' }}>per user/month</span>
                </Header>
                <Divider section hidden />
                <Card.Description>
                  <Button
                    as={Link}
                    fluid
                    to='/signup'
                    color='teal'
                    content='Try now'
                  />
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
  );
}
