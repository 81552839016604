import React, { useContext, useEffect, useState } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';

import { AppContext } from './AppContext';
import { CollapsedElements } from '../containers/RightMenu';

export const RightMenuContext = React.createContext();

export function RightMenuContextProvider({ children }) {
  const { mobile } = useContext(AppContext);
  const [initialLoad, setInitialLoad] = useState(true);

  const [clickedItem, setClickedItem] = useState(null);
  const [rightMenuExists, setRightMenuExists] = useState(false);
  const [rightMenuOpen, setRightMenuOpen] = useLocalStorage('rightMenuOpen', false);

  useEffect(() => {
    if (mobile && initialLoad) {
      setRightMenuOpen(false);
      setInitialLoad(false);
    }
  }, [mobile, initialLoad, setRightMenuOpen]);

  return (
    <RightMenuContext.Provider
      value={{
        clickedItem,
        setClickedItem,

        rightMenuExists,
        setRightMenuExists,

        rightMenuOpen,
        setRightMenuOpen,

        toCollapsedElements: (items) => (
          <CollapsedElements items={items} />
        ),
      }}
    >
      {children}
    </RightMenuContext.Provider>
  );
}
