import React, { useContext } from 'react';
import { List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { ProjectContext } from '../contexts/ProjectContext';
import { formatDuration } from '../utils/common';

export const WorksTable = ({ works, entries }) => {
  const { totalSpentTimeByWork } = useContext(ProjectContext);

  const worksTableRows = works
    .map((work) => ({
      ...work,
      timeSpent: totalSpentTimeByWork && totalSpentTimeByWork[work.id] ? formatDuration(totalSpentTimeByWork[work.id]) : '-',
    }))
    .map(({ id, name, timeSpent }) => (
      <List.Item key={name}>
        <List.Content style={styles.listContent}>
          <List.Header
            as={Link}
            to={`/works/${id}`}
            content={name}
          />
        </List.Content>
        <List.Description content={timeSpent}/>
      </List.Item>
    ));

  if (!works.length) {
    return (
      <p style={styles.noAssignedItems}>No works assigned yet</p>
    );
  }

  return (
    <List>
      { worksTableRows }
    </List>
  );
}

const styles = {
  listContent: {
    padding: 0,
  },
  noAssignedItems: {
    textAlign: 'center',
    padding: '20px 0',
  },
};
