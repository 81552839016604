import gql from 'graphql-tag';

export const ENTRY_CREATED_IN_PROJECT = gql`
  subscription entryCreatedInProject (
    $projectId: Float!
  ) {
    entryCreatedInProject (
      projectId: $projectId
    ) {
      id
      timezone
      start
      end
      completedUnits

      work {
        id
        name
        unit
      }

      worker {
        id
        name
      }

      device {
        id
        name
      }

      project {
        id
        name
      }
    }
  }
`;
