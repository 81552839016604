import React, { useState } from 'react';
import { Message, Divider, Button, Form } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';

import SIGN_UP from '../../graphql/mutations/signUp';
import { parseErrors } from '../../libs/errors';
import { AccountLayout } from '../../layouts/AccountLayout';

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    organizationName: '',
    email: '',
  });
  const [success, setSuccess] = useState(false);
  const [mutationErrors, setMutationErrors] = useState(null);

  const [signUpMutation] = useMutation(SIGN_UP, {
    variables: {
      data: formData,
    },
  });

  async function signUp() {
    try {
      setLoading(true);

      await signUpMutation();

      setMutationErrors(null);
      setSuccess(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      const errors = parseErrors(e);
      setMutationErrors(errors);
    }
  }

  function handleChange(e, { name, value }) {
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const errorMessage = mutationErrors && mutationErrors.general ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ mutationErrors.general }</p>
    </Message>
  ) : null;

  return (
    <AccountLayout
      headerText='Create new workspace'
      showAlreadySignedUp
    >
      { errorMessage }
      {
        success ? (
          <Message positive>
            <Message.Header>Your organization has been created!</Message.Header>
            <p>We've sent a verification letter to { formData.email }.</p>
          </Message>
        ) : (
          <Form
            size='small'
            noValidate
          >
            <Form.Input
              fluid
              required
              name='name'
              type='text'
              icon='user outline'
              iconPosition='left'
              placeholder='Full name'
              value={formData.name}
              error={mutationErrors ? mutationErrors.name : null}
              onChange={handleChange}
            />

            <Form.Input
              fluid
              required
              name='organizationName'
              type='text'
              icon='suitcase'
              iconPosition='left'
              placeholder='Company name'
              value={formData.organizationName}
              error={mutationErrors ? mutationErrors.organizationName : null}
              onChange={handleChange}
            />

            <Form.Input
              fluid
              required
              name='email'
              type='email'
              icon='at'
              iconPosition='left'
              placeholder='Email'
              value={formData.email}
              error={mutationErrors ? mutationErrors.email : null}
              onChange={handleChange}
            />

            <Divider hidden />

            <Button
              fluid
              content='Continue'
              color='teal'
              size='small'
              loading={loading}
              onClick={signUp}
            />
          </Form>
        )
      }
    </AccountLayout>
  );
};

export default SignUp;
