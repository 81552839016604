import React from 'react';
import { Dropdown, Button, Icon, Menu } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';

import GET_ACCOUNT from '../graphql/queries/getAccount';
import LOG_OUT from '../graphql/mutations/logOut';

export const AccountDropdown = withRouter(({ history }) => {
  const client = useApolloClient();

  const { data, loading } = useQuery(GET_ACCOUNT, {
    fetchPolicy: 'cache-first',
  });

  const [logOutMutation] = useMutation(LOG_OUT);

  async function logOut() {
    try {
      await logOutMutation();
    } catch(e) {
      console.log('Error logging out', e);
    }

    localStorage.removeItem('token');
    client.cache.reset();
    history.push('/');
  }

  const fullName = !loading ? data.account.email : null;

  return (
    <Menu.Item position='right'>
      <Dropdown
        basic
        loading={loading}
        as={Button}
        color='grey'
        text={fullName}
      >
        <Dropdown.Menu style={styles.dropdown}>
          <Dropdown.Item as={Link} to='/payments'>
            <Icon name='credit card outline' />Payments
          </Dropdown.Item>

          <Dropdown.Item onClick={logOut}>
            <Icon name='log out' />Log out
          </Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  );
});

const styles = {
  dropdown: {
    boxShadow: 'none',
  },
};
