import React, { useState } from 'react';
import { Message, Button, Form, Modal } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';

import CREATE_ACCOUNT from '../graphql/mutations/createAccount';
import { parseErrors } from '../libs/errors';

export const CreateAccountModal = ({ isOpen, setOpen }) => {
  const close = () => setOpen(false);

  const [formData, setFormData] = useState({
    email: '',
  });

  const [mutationLoading, setMutationLoading] = useState(false);
  const [mutationErrors, setMutationErrors] = useState(null);

  const [createAccountMutation] = useMutation(CREATE_ACCOUNT, {
    variables: {
      entity: formData,
    },
    refetchQueries: ['getAccounts'],
  });

  async function createAccount() {
    try {
      setMutationLoading(true);
      await createAccountMutation();

      close();
    } catch (e) {
      setMutationLoading(false);

      const errors = parseErrors(e);
      setMutationErrors(errors);
    }
  }

  function handleChange(e, { name, value, checked }) {
    setFormData({
      ...formData,
      [name]: checked !== undefined ? checked : value,
    });
  }

  const errorMessage = mutationErrors && mutationErrors.general ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ mutationErrors.general }</p>
    </Message>
  ) : null;

  return (
    <Modal
      size='tiny'
      dimmer='blurring'
      open={isOpen}
      onClose={close}
    >
      <Modal.Header>Invite new member to organization</Modal.Header>
      <Modal.Content>
        { errorMessage }
        <Form
          size='small'
          id='modal-form'
        >
          <Form.Input
            fluid
            required
            name='email'
            label='Email'
            value={formData.email}
            error={mutationErrors ? mutationErrors.email : null}
            onChange={handleChange}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content='Cancel'
          onClick={close}
        />
        <Button
          form='modal-form'
          color='teal'
          icon='send'
          content='Send invite'
          loading={mutationLoading}
          disabled={mutationLoading}
          onClick={createAccount}
        />
      </Modal.Actions>
    </Modal>
  );
};
