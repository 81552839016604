import { useContext, useState } from 'react';

import { EntriesApprovalContext } from '../contexts/EntriesApprovalContext';

function removeTextSelection() {
  if (window.getSelection) {
    if (window.getSelection().empty) {  // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {  // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {  // IE?
    document.selection.empty();
  }
}

export const useEntriesApprovals = () => {
  const { selectedRows, setSelectedRows } = useContext(EntriesApprovalContext);

  const [selectingMode, setSelectingMode] = useState(true);
  const [mouseDown, setMouseDown] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [startingRow, setStartingRow] = useState(null);

  function handleRowClick(event, entry) {
    // This function is called even if child is clicked,
    // but not link or icon children nodes (<a>, <i>).
    if (
      !dragging
      && entry.end
      && event.target !== event.currentTarget
      && event.target.nodeName.toLowerCase() !== 'a'
      && event.target.nodeName.toLowerCase() !== 'i'
    ) {
      if (selectedRows.includes(entry.id)) {
        setSelectedRows([...selectedRows.filter((rowId) => rowId !== entry.id)]);
      } else {
        setSelectedRows([...selectedRows, entry.id]);
      }
    }

    setDragging(false);
  }

  function handleMouseDown(entry) {
    if (!entry.end) return

    setSelectingMode(!selectedRows.includes(entry.id))
    setMouseDown(true);
    setStartingRow(entry.id);
  }

  function handleMouseUp(entry) {
    setMouseDown(false);

    if (entry.id !== startingRow) {
      setDragging(false);
    }
  }

  function handleMouseEnter(entry) {
    if (mouseDown && entry.end) {
      const rows = selectingMode
        ? [...selectedRows]
          .concat(!selectedRows.includes(startingRow) ? [startingRow] : [])
          .concat(!selectedRows.includes(entry.id) ? [entry.id] : [])
        : [ ...selectedRows.filter(rowId => rowId !== entry.id && rowId !== startingRow) ];


      removeTextSelection();
      setSelectedRows(rows);
      setDragging(true);
    }
  }

  return (entry) => ({
      style: {...(selectedRows.includes(entry.id) && styles.selectedRow)},
      onClick: (event) => handleRowClick(event, entry),
      onMouseDown: () => handleMouseDown(entry),
      onMouseUp: () => handleMouseUp(entry),
      onMouseEnter: () => handleMouseEnter(entry),
  });
}

const styles = {
  selectedRow: {
    backgroundColor: 'rgba(27, 182, 175, 0.15)',
  }
}
