import React, { createContext, createRef, useState } from 'react';

export const EntriesApprovalContext = createContext({});

export const EntriesApprovalContextProvider = ({ children }) => {
  const approvalDetailsRef = createRef();
  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <EntriesApprovalContext.Provider
      value={{
        approvalDetailsRef,
        selectedRows,
        setSelectedRows,
      }}
    >
      { children }
    </EntriesApprovalContext.Provider>
  );
}
