import React, { useState } from 'react';
import { Tab } from 'semantic-ui-react';

import CommonLayout from '../../layouts/CommonLayout';
import { PaymentSettings } from './components/PaymentSettings';

const Settings = () => {
  const panes = [
    { menuItem: 'Payment method', render: () => <PaymentSettings /> },
  ];

  return (
    <CommonLayout
      header={{
        text: 'Payments',
      }}

      surface={
        <React.Fragment>
          <Tab
            menu={{ secondary: true, pointing: true, style: { borderBottomWidth: '1px', margin: '20px 0 0' } }}
            panes={panes}
          />
        </React.Fragment>
      }
    />
  );
};

export default Settings;
