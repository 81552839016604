import React, { useState } from 'react';
import { Button, Icon, Table, Segment, Loader, Message } from 'semantic-ui-react';
import { useQuery } from '@apollo/react-hooks';

import { GET_PAYMENT_METHODS } from '../../../graphql/queries/getPaymentMethods';
import { SetPaymentMethod } from './SetPaymentMethod';
import { HorizontalList } from '../../../components/HorizontalList';

const MaskedNumberHeader = () => (
  <React.Fragment>
    <Icon name='credit card outline' />Card details
  </React.Fragment>
);

export const PaymentSettings = () => {
  const [updatePaymentInfo, setUpdatePaymentInfo] = useState(false);
  const { data = {}, loading, error } = useQuery(GET_PAYMENT_METHODS);

  if (loading) {
    return (
      <Segment basic padded='very'>
        <Loader active />
      </Segment>
    );
  }

  if(error) {
    return (
      <Message error>
        <Message.Header>Error has occured</Message.Header>
        <p>Error loading payment methods from remote service. Try again later.</p>
      </Message>
    );
  }

  if(!data.paymentMethods.length || updatePaymentInfo) {
    return (
      <SetPaymentMethod
        updatePaymentInfo={updatePaymentInfo}
        setUpdatePaymentInfo={setUpdatePaymentInfo}
      />
    );
  }

  return (
    <React.Fragment>
      <Table
        fixed
        singleLine
        basic='very'
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell><MaskedNumberHeader /></Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            data.paymentMethods.map(({ cardType, maskedNumber, expirationDate }) => (
              <Table.Row key={maskedNumber}>
                <Table.Cell>
                  {
                    maskedNumber
                  }
                  <HorizontalList
                    items={[cardType, expirationDate]}
                  />
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  <Button
                    content='Update payment info'
                    onClick={() => setUpdatePaymentInfo(true)}
                  />
                </Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};
