import React, { useContext, useState } from 'react';
import { Image, Visibility, Icon, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import LogoSmall from '../assets/logo_small.png';
import { AccountDropdown } from '../components/AccountDropdown';
import { AppContext } from '../contexts/AppContext';
import { LeftMenuContext } from '../contexts/LeftMenuContext';
import { RightMenuContext } from '../contexts/RightMenuContext';

export const LogoButton = () => {
  const { leftMenuOpen } = useContext(LeftMenuContext);

  return (
    <Menu.Item
      header
      as={Link}
      to='/projects'
      style={{
        ...styles.logoButton,
        width: leftMenuOpen ? '200px' : '60px',
      }}
    >
      <Image
        src={LogoSmall}
        size='mini'
      />
    </Menu.Item>
  );
}

const LeftMenuToggleButton = () => {
  const { leftMenuOpen, openLeftMenu, closeLeftMenu } = useContext(LeftMenuContext);

  return (
    <Menu.Item
      onClick={leftMenuOpen ? closeLeftMenu : openLeftMenu}
    >
      <Icon name='sidebar' />
    </Menu.Item>
  );
};

export const UpperMenu = () => {
  const { mobile } = useContext(AppContext);
  const { rightMenuOpen } = useContext(RightMenuContext);
  const [show, setShow] = useState(true);

  function handleUpdate(e, { calculations }) {
    if (!rightMenuOpen) {
      setShow(calculations.direction === 'up');
    }
  }

  return (
    <Menu
      borderless
      fixed='top'
      size='large'
      style={{
        ...styles.menu,
        opacity: show ? '100' : '0',
      }}
    >
      {
        mobile ? (
          <React.Fragment>
            <Visibility onUpdate={handleUpdate} />
            <LeftMenuToggleButton />
            <AccountDropdown />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <LogoButton />
            <AccountDropdown />
          </React.Fragment>
        ) 
      }
    </Menu>
  );
};

const styles = {
  menu: {
    borderLeftWidth: 0,
    borderRightWidth: 0,
    boxShadow: 'none',
    margin: 0,
    height: '60px',
  },
  logoButton: {
    marginRight: '-60px',
  },
};
