import gql from 'graphql-tag';

const DOWNLOAD_REPORT = gql`
  mutation downloadReport (
    $data: EntryFilters!,
  ) {
    downloadReport (
      data: $data,
    )
  }
`;

export default DOWNLOAD_REPORT;
