import React from 'react';
import { Label } from 'semantic-ui-react';

function toValidationErrorsObject(acc, error) {
  const errorHolder = Object.values(error.constraints)
    .map(msg => msg.replace(/^\S+/g, 'This field'))
    .map(( msg, i ) => (
      <React.Fragment key={i}>
        { msg } <br/>
      </React.Fragment>
    ));

  acc[error.property] = <Label>{ errorHolder }</Label>;

  return acc;
}

export function parseErrors(error) {
  if (error.networkError) {
    return {
      general: 'Network error. Check your connection.'
    };
  } else if (error.graphQLErrors) {
    const validationErrors = error.graphQLErrors
      .filter(error => error.message === 'Argument Validation Error')
      .reduce((acc, error) => [...acc, ...error.extensions.exception.validationErrors], [])

    return validationErrors.length
      ? validationErrors.reduce(toValidationErrorsObject, {})
      : { general: error.graphQLErrors[0].message};
  }

  return { general: error.message };
}
