import React, { useState } from 'react';
import { Confirm, Button, Modal, Header, Popup } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';

import { REMOVE_ACCOUNT } from '../../../graphql/mutations/removeAccount';

export const AccountRemoveButton = ({ account }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [mutationLoading, setMutationLoading] = useState(false);
  const [removeAccountMutation] = useMutation(REMOVE_ACCOUNT, {
    variables: {
      accountId: Number(account.id),
    },
    refetchQueries: ['getAccounts'],
  });

  async function removeAccount() {
    try {
      setMutationLoading(true);
      await removeAccountMutation();
    } catch (e) {
      setMutationLoading(false);
    }
  }

  async function handleClick() {
    setConfirmOpen(false);

    if (!mutationLoading) {
      await removeAccount();
    }
  }

  return (
    <React.Fragment>
      <Popup
        inverted
        size='mini'
        content='Remove account'
        trigger={
          <Button
            basic
            icon='trash'
            color='red'
            size='mini'
            disabled={account.root}
            loading={mutationLoading}
            onClick={() => setConfirmOpen(true)}
          />
        }
      />

      <Confirm
        basic
        size='tiny'
        content={
          <Modal.Content>
            This member will no longer be able to access this organization. All active sessions will be terminated.
            <br/>
            <br/>
            Continue?
          </Modal.Content>
        }
        header={
          <Header icon='trash' content={`Remove ${account.email}?`} />
        }
        confirmButton={
          <Button
            basic
            inverted
            color='teal'
            icon='checkmark'
            content='Yes'
            onClick={handleClick}
          />
        }
        cancelButton={
          <Button
            basic
            inverted
            color='red'
            icon='remove'
            content='No'
            onClick={() => setConfirmOpen(false)}
          />
        }
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
      />
    </React.Fragment>
  );
}
