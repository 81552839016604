import 'moment-timezone';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import React, { useContext, useEffect, useState } from 'react';
import { Transition, Table, Message, Loader, Icon, Segment, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { AppContext } from '../contexts/AppContext';
import {SET_ENTRIES_PENDING} from '../graphql/mutations/setEntriesPending';
import { parseErrors } from '../libs/errors';

momentDurationFormatSetup(moment);

const ProjectHeader = () => (
  <React.Fragment>
    <Icon name='clipboard outline' />Project
  </React.Fragment>
);

const WorkerHeader = () => (
  <React.Fragment>
    <Icon name='id badge outline' />Worker
  </React.Fragment>
);

const WorkHeader = () => (
  <React.Fragment>
    <Icon name='tasks' />Work
  </React.Fragment>
);

const DeviceHeader = () => (
  <React.Fragment>
    <Icon name='mobile alternate' />Device
  </React.Fragment>
);

const FromHeader = () => (
  <React.Fragment>
    <Icon name='clock outline' />From
  </React.Fragment>
);

const ToHeader = () => (
  <React.Fragment>
    <Icon name='clock outline' />To
  </React.Fragment>
);

const DurationHeader = () => (
  <React.Fragment>
    <Icon name='clock outline' />Duration
  </React.Fragment>
);

const CompletedUnitsHeader = () => (
  <React.Fragment>
    <Icon name='heart outline' />Units
  </React.Fragment>
);

const StatusHeader = () => (
  <React.Fragment>
    <Icon name='circle outline' />Status
  </React.Fragment>
);

function getDuration(start, end) {
  const startDate = moment(Number(start));
  const endDate = moment(Number(end));

  const difference = moment(endDate).diff(startDate);

  return moment.duration(difference).format('hh:mm:ss', { trim: false });
}

function getDurationValue(start, end) {
  const startDate = moment(Number(start));
  const endDate = moment(Number(end));

  const difference = moment(endDate).diff(startDate);

  return difference;
}

function capitalize (word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const ApprovedTick = ({ id, status, enableApprovals }) => {
  const { setError } = useContext(AppContext);

  const [hover, setHover] = useState(false);
  const [mutationLoading, setMutationLoading] = useState(false);

  const [setPendingMutation] = useMutation(SET_ENTRIES_PENDING, {
    variables: {
      data: {
        entries: [id],
      },
    },
    refetchQueries: ['getEntries'],
  });

  async function setPending() {
    if (!enableApprovals) {
      return;
    }

    try {
      setMutationLoading(true);

      await setPendingMutation();

      setHover(false);
    } catch (e) {
      setMutationLoading(false);

      const errors = parseErrors(e);
      setError(errors);
    }
  }

  useEffect(() => {
    if (status === 'approved') {
      setMutationLoading(false);
    }
  }, [status]);

  if (status !== 'approved') {
    return null;
  }

  if (mutationLoading) {
    return (
      <Loader size='mini' inline active />
    );
  }

  return (
    <Icon
      name={hover ? 'times circle' : 'check circle'}
      color={hover ? 'grey' : 'green'}
      style={{
        ...(enableApprovals && styles.approvedTick),
      }}
      onMouseEnter={() => enableApprovals && setHover(true)}
      onMouseLeave={() => enableApprovals && setHover(false)}
      onClick={setPending}
    />
  );
}

function groupByProjectName(entriesData, property) {
  return entriesData.reduce(function (acc, obj) {
    let key = obj.project[property]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}

function groupByWorkerName(entriesData, property) {
  return entriesData.reduce(function (acc, obj) {
    let key = obj.worker[property]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}

function groupByWorkName(entriesData, property) {
  return entriesData.reduce(function (acc, obj) {
    let key = obj.work[property]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}

const EntriesTableSummary = ({
  hideProjects = false,
  hideWorkers = false,
  hideWorks = false,
  hideSum = false,
  entries,
}) => {
  return (
    <Segment
      basic
      style={styles.summary}
    >
      <Table
        compact
        fixed
        singleLine
        unstackable
        basic='very'
      >
        <Table.Header>
          <Table.Row>
            { !hideProjects && <Table.HeaderCell><ProjectHeader />{'s'}</Table.HeaderCell> }
            { !hideWorkers && <Table.HeaderCell><WorkerHeader />{'s'}</Table.HeaderCell> }
            { !hideWorks && <Table.HeaderCell><WorkHeader />{'s'}</Table.HeaderCell> }
            <Table.HeaderCell><DurationHeader /></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { !hideProjects && Object.keys(groupByProjectName(entries, 'name')).map((key) => (
            <Table.Row key={key}>
              { !hideProjects && <Table.Cell>{key}</Table.Cell> }
              { !hideWorkers && <Table.Cell></Table.Cell> }
              { !hideWorks && <Table.Cell></Table.Cell> }
              { !hideProjects && <Table.Cell>
                {
                  moment.duration(groupByProjectName(entries, 'name')[key].reduce((accQty, { start, end }) => accQty + (end ? getDurationValue(start, end) : 0), 0)).format('hh:mm:ss', { trim: false })
                }
              </Table.Cell> }
          </Table.Row>)) }
          { !hideWorks && Object.keys(groupByWorkName(entries, 'name')).map((key) => (
            <Table.Row key={key}>
              { !hideProjects && <Table.Cell></Table.Cell> }
              { !hideWorkers && <Table.Cell></Table.Cell> }
              { !hideWorks && <Table.Cell>{key}</Table.Cell> }
              { !hideWorks && <Table.Cell>
                {
                  moment.duration(groupByWorkName(entries, 'name')[key].reduce((accQty, { start, end }) => accQty + (end ? getDurationValue(start, end) : 0), 0)).format('hh:mm:ss', { trim: false })
                }
              </Table.Cell> }
          </Table.Row>)) }
          { !hideWorkers && Object.keys(groupByWorkerName(entries, 'name')).map((key) => (
            <Table.Row key={key}>
              { !hideProjects && <Table.Cell></Table.Cell> }
              { !hideWorkers && <Table.Cell>{key}</Table.Cell> }
              { !hideWorks && <Table.Cell></Table.Cell> }
              { !hideWorkers && <Table.Cell>
                {
                  moment.duration(groupByWorkerName(entries, 'name')[key].reduce((accQty, { start, end }) => accQty + (end ? getDurationValue(start, end) : 0), 0)).format('hh:mm:ss', { trim: false })
                }
              </Table.Cell> }
          </Table.Row>)) }
          { !hideSum && <Table.Row>
            { !hideProjects && !hideSum && <Table.Cell></Table.Cell> }
            { !hideWorkers && !hideSum && <Table.Cell></Table.Cell> }
            { !hideWorks && !hideSum && <Table.Cell></Table.Cell> }
            <Table.Cell>
              {
                'Σ= ' + moment.duration(entries.reduce((accQty, { start, end }) => accQty + (end ? getDurationValue(start, end) : 0), 0)).format('hh:mm:ss', { trim: false })
              }
            </Table.Cell>
          </Table.Row>}
        </Table.Body>
      </Table>
    </Segment>
  );
}

const EntriesTableComputer = ({
  enableApprovals = false,
  hideProjects = false,
  hideWorkers = false,
  hideWorks = false,
  hideDevices = false,
  hideUnits = false,
  applyModifiers,
  entries,
}) => {
  return (
    <Segment
      basic
      style={styles.outerSegment}
    >
      <Table
        fixed
        singleLine
        unstackable
        basic='very'
        style={styles.table}
      >
        <Table.Header>
          <Table.Row>
            { !hideProjects && <Table.HeaderCell width={3}><ProjectHeader /></Table.HeaderCell> }
            { !hideWorkers && <Table.HeaderCell width={3}><WorkerHeader /></Table.HeaderCell> }
            { !hideWorks && <Table.HeaderCell width={3}><WorkHeader /></Table.HeaderCell> }
            { !hideDevices && <Table.HeaderCell width={3}><DeviceHeader /></Table.HeaderCell> }
            <Table.HeaderCell width={3}><FromHeader /></Table.HeaderCell>
            <Table.HeaderCell width={3}><ToHeader /></Table.HeaderCell>
            <Table.HeaderCell width={3}><DurationHeader /></Table.HeaderCell>
            { !hideUnits && <Table.HeaderCell width={3}><CompletedUnitsHeader /></Table.HeaderCell> }
            <Table.HeaderCell width={1}/>
          </Table.Row>
        </Table.Header>

        <Transition.Group as={Table.Body} duration={500} animation={'slide up'} size='huge'>
        {
          entries.map((entry) => {
            const { id, start, end, status, timezone, completedUnits, worker, work, device, project } = entry;
            return (
              <Table.Row
                key={id}
                {...(applyModifiers && applyModifiers(entry))}
              >
                {!hideProjects && (
                  <Table.Cell>
                    <Link to={`/projects/${project.id}`}>
                      { project.name }
                    </Link>
                  </Table.Cell>
                )}

                {!hideWorkers && (
                  <Table.Cell>
                    <Link to={`/workers/${worker.id}`}>
                      { worker.name }
                    </Link>
                  </Table.Cell>
                )}

                {!hideWorks && (
                  <Table.Cell>
                    <Link to={`/works/${work.id}`}>
                      { work.name }
                    </Link>
                  </Table.Cell>
                )}

                {!hideDevices && (
                  <Table.Cell>
                    {
                      device ? (
                        <Link to={`/devices/${device.id}`}>
                          { device.name }
                        </Link>
                      ) : '-'
                    }
                  </Table.Cell>
                )}

                <Table.Cell title={timezone || null}>
                  {
                    timezone
                      ? moment(Number(start)).tz(timezone).format('DD.MM.YYYY HH:mm')
                      : moment(Number(start)).format('DD.MM.YYYY HH:mm')
                  }
                </Table.Cell>

                <Table.Cell title={end && timezone ? timezone : null}>
                  {
                    end
                      ? timezone
                      ? moment(Number(end)).tz(timezone).format('DD.MM.YYYY HH:mm')
                      : moment(Number(end)).format('DD.MM.YYYY HH:mm')
                      : '-'
                  }
                </Table.Cell>

                <Table.Cell>
                  { end ? getDuration(start, end) : '-' }
                </Table.Cell>

                {!hideUnits && (
                  <Table.Cell>
                    { completedUnits ? `${completedUnits} ${work.unit}` : '-' }
                  </Table.Cell>
                )}

                <Table.Cell textAlign='center'>
                  <ApprovedTick
                    id={id}
                    status={status}
                    enableApprovals={enableApprovals}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })
        }
        </Transition.Group>
      </Table>
    </Segment>
  );
}

const EntriesTableMobile = ({
  hideProjects = false,
  hideWorkers = false,
  hideWorks = false,
  hideDevices = false,
  hideUnits = false,
  entries,
}) => {
  return entries.map(({ id, start, end, timezone, status, completedUnits, worker, work, device, project }) => {
    return (
      <Table key={id} unstackable basic>
        <Table.Body>
          {!hideProjects && (
            <Table.Row>
              <Table.Cell><ProjectHeader /></Table.Cell>
              <Table.Cell textAlign='right'>
                <Link to={`/projects/${project.id}`}>
                  { project.name }
                </Link>
              </Table.Cell>
            </Table.Row>
          )}

          {!hideWorkers && (
            <Table.Row>
              <Table.Cell><WorkerHeader /></Table.Cell>
              <Table.Cell textAlign='right'>
                <Link to={`/workers/${worker.id}`}>
                  { worker.name }
                </Link>
              </Table.Cell>
            </Table.Row>
          )}

          {!hideWorks && (
            <Table.Row>
              <Table.Cell><WorkHeader /></Table.Cell>
              <Table.Cell textAlign='right'>
                <Link to={`/works/${work.id}`}>
                  { work.name }
                </Link>
              </Table.Cell>
            </Table.Row>
          )}

          {!hideDevices && (
            <Table.Row>
              <Table.Cell><DeviceHeader /></Table.Cell>
              <Table.Cell textAlign='right'>
                {
                  device ? (
                    <Link to={`/devices/${device.id}`}>
                      { device.name }
                    </Link>
                  ) : '-'
                }
              </Table.Cell>
            </Table.Row>
          )}

          <Table.Row>
            <Table.Cell><FromHeader /></Table.Cell>
            <Table.Cell textAlign='right' title={timezone || null}>
              {
                timezone
                  ? moment(Number(start)).tz(timezone).format('DD.MM.YYYY HH:mm')
                  : moment(Number(start)).format('DD.MM.YYYY HH:mm')
              }
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell><ToHeader /></Table.Cell>
            <Table.Cell textAlign='right' title={end && timezone ? timezone : null}>
              {
                end
                  ? timezone
                  ? moment(Number(end)).tz(timezone).format('DD.MM.YYYY HH:mm')
                  : moment(Number(end)).format('DD.MM.YYYY HH:mm')
                  : '-'
              }
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell><DurationHeader /></Table.Cell>
            <Table.Cell textAlign='right'>
              { end ? getDuration(start, end) : '-' }
            </Table.Cell>
          </Table.Row>

          {!hideUnits && (
            <Table.Row>
              <Table.Cell><CompletedUnitsHeader /></Table.Cell>
              <Table.Cell textAlign='right'>
                { completedUnits ? `${completedUnits} ${work.unit}` : '-' }
              </Table.Cell>
            </Table.Row>
          )}

          <Table.Row>
            <Table.Cell><StatusHeader /></Table.Cell>
            <Table.Cell textAlign='right'>
              { status ? capitalize(status) : '-' }
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  });
}

export const EntriesTable = (props) => {
  const {
    hideFilters = false,
    entriesData,
    entriesLoading,
  } = props;

  const { mobile } = useContext(AppContext);
  const [entries, setEntries] = useState([]);
  const [firstFetch, setFirstFetch] = useState(true);

  useEffect(() => {
    if (!entriesLoading && entriesData.entries) {
      setFirstFetch(false);
      setEntries(entriesData.entries);
    }
  }, [entriesData, entriesLoading])

  if (entriesLoading && firstFetch) {
    return (
      <Segment basic padded='very'>
        <Loader active />
      </Segment>
    );
  }

  const noResults = !entries.length ? (
    <Message>
      <Message.Header>No results found</Message.Header>
      <Message.Content>
        {
          hideFilters
            ? 'There is no activity yet.'
            : 'Either there is no activity with specified filters or no activity at all.'
        }
      </Message.Content>
    </Message>
  ) : null;

  return (
    <React.Fragment>
      <Header style={styles.tableHeader}>Summary</Header>
      <EntriesTableSummary {...props} entries={entries} hideWorks={true} hideWorkers={true} />
      <EntriesTableSummary {...props} entries={entries} hideProjects={true} hideWorkers={true} hideSum={true}/>
      <EntriesTableSummary {...props} entries={entries} hideProjects={true} hideWorks={true} hideSum={true}/>
      <Header style={styles.tableHeader}>All Entries</Header>
      { mobile ?
        <EntriesTableMobile {...props} entries={entries} />
        :
        <EntriesTableComputer {...props} entries={entries} />
      }
      { noResults }
    </React.Fragment>
  );
};

const styles = {
  outerSegment: {
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'column',
    height: '100%',
    overflowX:'scroll',
  },
  table: {
    minWidth: '1000px',
  },
  tableHeader: {
    marginTop: 20,
  },
  approvedTick: {
    cursor: 'pointer',
  },
}
