import gql from 'graphql-tag';

const CREATE_PROJECT = gql`
  mutation (
    $entity: ProjectCreateInput!
  ) {
    createProject (
      entity: $entity
    )
  }
`;

export default CREATE_PROJECT;
