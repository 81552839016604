import React from 'react';
import { Grid } from 'semantic-ui-react';

import { WorkerInfoTable } from './WorkerInfoTable';
import { AssignedProjectsTable } from './AssignedProjectsTable';

export const OverviewTab = ({ worker }) => {
  return (
    <Grid
      doubling
      columns={2}
      padded='vertically'
    >
      <Grid.Column>
        <WorkerInfoTable worker={worker} />
      </Grid.Column>

      <Grid.Column>
        <AssignedProjectsTable worker={worker} />
      </Grid.Column>
    </Grid>
  );
};
