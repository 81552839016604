import React, { useState } from 'react';
import { Tab } from 'semantic-ui-react';

import CommonLayout from '../../layouts/CommonLayout';
import CreateWorktModal from '../../modals/createWorkModal';
import { WorksTable } from './components/WorksTable';

const Works = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const panes = [
    { menuItem: 'Active', render: () => <WorksTable /> },
    { menuItem: 'Archived', render: () => <WorksTable archived /> },
  ];

  return (
    <CommonLayout
      header={{
        text: 'Works',
        buttons: [{
          text: 'Create new work',
          color: 'teal',
          onClick: () => setModalOpen(true),
        }]
      }}

      surface={
        <React.Fragment>
          {
            isModalOpen ? (
              <CreateWorktModal
                isOpen={isModalOpen}
                setOpen={setModalOpen}
              />

            ) : null
          }
          <Tab
            menu={{ secondary: true, pointing: true, style: { borderBottomWidth: '1px', margin: '20px 0 0' } }}
            panes={panes}
          />
        </React.Fragment>
      }
    />
  );
};

export default Works;
