import React, { useEffect, useState } from 'react';
import { Icon, Message, Button, Modal, Dimmer, Loader } from 'semantic-ui-react';
import gql from "graphql-tag";
import { useMutation, useSubscription } from '@apollo/react-hooks';

import { parseErrors } from '../libs/errors';

const GENERATE_AUTH_CODE = gql`
  mutation (
    $deviceId: Float!
  ) {
    generateAuthCode (
      deviceId: $deviceId,
    )
  }
`;

const ON_DEVICE_SIGNED_IN = gql`
  subscription (
    $deviceId: Float!
  ) {
    onDeviceSignedIn(deviceId: $deviceId)
  }
`;

const LinkMobileDevice = ({ isOpen, setOpen, deviceId, linkedAt, refetchDevice }) => {
  const [authCode, setAuthCode] = useState('');
  const [mutationLoading, setMutationLoading] = useState(true);
  const [mutationErrors, setMutationErrors] = useState(null);
  const [success, setSuccess] = useState(false);

  const close = () => {
    if (success) {
      refetchDevice();
    }

    setOpen(false);
  };

  const { data } = useSubscription(ON_DEVICE_SIGNED_IN, {
    variables: {
      deviceId: Number(deviceId),
    },
  });

  useEffect(() => {
    if (data && data.onDeviceSignedIn) {
      setSuccess(true);
    }
  }, [data])

  const [generateAuthCodeMutation] = useMutation(GENERATE_AUTH_CODE, {
    variables: {
      deviceId: Number(deviceId),
    },
  });

  useEffect(() => {
    async function generateAuthCode() {
      try {
        const { data } = await generateAuthCodeMutation();

        setAuthCode(data.generateAuthCode);
        setMutationLoading(false);
      } catch (e) {
        setMutationLoading(false);

        const errors = parseErrors(e);
        setMutationErrors(errors);
      }
    }

    if (isOpen) {
      generateAuthCode();
    }
  }, [isOpen, generateAuthCodeMutation]);

  const downloadAppMessage = (
    <Message
      onClick={() => window.open('https://play.google.com/store/apps/details?id=com.onejoule')}
      style={{ cursor: 'pointer' }}
      color='teal'
      icon
    >
      <Icon name='google play' />
      <Message.Content>
        <Message.Header>Install 1Joule Edge app on your device</Message.Header>
        Click this message to download our app from Google Play
      </Message.Content>
    </Message>
  );

  return (
    <Modal
      size='tiny'
      dimmer={'blurring'}
      open={isOpen}
      onClose={close}
    >
      <Modal.Header>Link mobile device</Modal.Header>
      <Modal.Content>
        <Dimmer
          inverted
          active={mutationLoading}
        >
          <Loader inverted content='Loading' />
        </Dimmer>
        {
          success ? (
            <Message positive>
              <Message.Header>Success!</Message.Header>
              <p>New mobile device has been linked successfully.</p>
            </Message>
          ) : (
            <React.Fragment>
              {
                mutationErrors && mutationErrors.general ? (
                  <Message negative>
                    <Message.Header>Error has occured</Message.Header>
                    <p>{ mutationErrors.general }</p>
                  </Message>
                ) : (
                  <React.Fragment>
                    { downloadAppMessage }
                    <Message success>
                      <Message.Header>Authorization code is ready</Message.Header>
                      <p>Type following code on your device to link it to the management console</p>
                    </Message>

                    <p style={styles.authCode}> { authCode ? authCode : 'XXXXXXXX' } </p>
                  </React.Fragment>
                )
              }
            </React.Fragment>
          )
        }
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={ success ? 'OK' : 'Cancel' }
          color={ success ? 'green' : null }
          onClick={close}
        />
      </Modal.Actions>
    </Modal>
  );
};

const styles = {
  authCode: {
    textAlign: 'center',
    fontSize: '6em',
    fontWeight: 'lighter',
    padding: '0.25em 0',
  },
};

export default LinkMobileDevice;
