import gql from 'graphql-tag';

const GET_WORKERS = gql`
  query getWorkers (
    $filters: WorkerFilters
    $orderBy: WorkerOrder
  ) {
    workers (
      filters: $filters
      orderBy: $orderBy
    ) {
      id
      name
      code
      phoneNumber
      createdAt

      projects {
        id
        name
      }
    }
  }
`;

export default GET_WORKERS;
