import gql from 'graphql-tag';

export const ARCHIVE_DEVICE = gql`
  mutation (
    $entity: EntityArchiveInput!
  ) {
    archiveDevice (
      entity: $entity,
    )
  }
`;
