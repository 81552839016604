import React from 'react';
import { Table, Message, Loader, Icon, Popup, Segment, Label } from 'semantic-ui-react';
import { useQuery } from '@apollo/react-hooks';

import GET_ACCOUNT from '../../../graphql/queries/getAccount';
import GET_ACCOUNTS from '../../../graphql/queries/getAccounts';
import { HorizontalList } from '../../../components/HorizontalList';
import { AccountRemoveButton } from './AccountRemoveButton';

export const AccountsTable = () => {
  const { data: accountData = {}, loading: accountLoading } = useQuery(GET_ACCOUNT, {
    fetchPolicy: 'cache-first',
  });
  const { data: accountsData = {}, loading: accountsLoading } = useQuery(GET_ACCOUNTS);

  const loading = accountLoading || accountsLoading;

  if (loading) {
    return (
      <Segment basic padded='very'>
        <Loader active />
      </Segment>
    );
  }

  if (!accountsData.accounts.length) {
    return (
      <Message>
        <Message.Header>No results found</Message.Header>
        <p>You don't have any accounts, which is just impossible.</p>
      </Message>
    );
  }

  return (
    <React.Fragment>
      <Table unstackable basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell><Icon name='user circle outline' />Account</Table.HeaderCell>
            <Table.HeaderCell width={1}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {
            accountsData.accounts.map((account) => (
              <Table.Row key={account.email}>
                <Table.Cell>
                  <HorizontalList noDot items={[
                    account.email,
                    account.email === accountData.account.email ? (
                      <Label
                        basic
                        horizontal
                        color='green'
                        content={`It's you`}
                      />
                    ) : null,
                    !account.invitationAccepted ? (
                      <Popup
                        inverted
                        size='mini'
                        content='Invite sent, but not accepted yet'
                        trigger={
                          <Label
                            basic
                            horizontal
                            icon={<Icon name='send' style={{ margin: 0 }}/>}
                            color='green'
                          />
                        }
                      />
                    ) : null,
                  ]} />
                </Table.Cell>
                <Table.Cell>
                  <AccountRemoveButton account={account} />
                </Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};
