import React from 'react';
import { Grid } from 'semantic-ui-react';

import { DeviceInfoTable } from './DeviceInfoTable';
import { AssignedProjectsTable } from './AssignedProjectsTable';

export const OverviewTab = ({ device }) => {
  return (
    <Grid
      doubling
      columns={2}
      padded='vertically'
    >
      <Grid.Column>
        <DeviceInfoTable device={device} />
      </Grid.Column>

      <Grid.Column>
        <AssignedProjectsTable device={device} />
      </Grid.Column>
    </Grid>
  );
};
