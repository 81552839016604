import gql from 'graphql-tag';

const RESET_PASSWORD = gql`
  mutation verifyEmail (
    $data: UserResetPasswordInput!
  ) {
    resetPassword (
      data: $data
    )
  }
`;

export default RESET_PASSWORD;
