import moment from 'moment';
import React, { useContext } from 'react';
import { Table, Message, Loader, Icon, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { AppContext } from '../../../contexts/AppContext';
import GET_PROJECTS from '../../../graphql/queries/getProjects';
import { HorizontalList } from '../../../components/HorizontalList';
import { ProjectStatusLabel } from '../../../components/ProjectStatusLabel';

export const AllProjects = ({ filter }) => {
  const { mobile } = useContext(AppContext);
  const { data = {}, loading } = useQuery(GET_PROJECTS);

  if (loading) {
    return (
      <Segment basic padded='very'>
        <Loader active />
      </Segment>
    );
  }

  const projectsFilter = filter ? filter : () => true;
  const filteredProjects = data.projects.filter(projectsFilter);

  if (!filteredProjects.length) {
    return (
      <Message>
        <Message.Header>No data</Message.Header>
        <p>No available projects</p>
      </Message>
    );
  }

  return (
    <Table unstackable fixed singleLine basic='very'>
      <Table.Header>
        <Table.Row>
          {
            !mobile ? (
              <Table.HeaderCell width={2}>
                <React.Fragment>
                  <Icon name='circle outline' /> Status
                </React.Fragment>
              </Table.HeaderCell>
            ) : null
          }
          <Table.HeaderCell><Icon name='clipboard outline' /> Project</Table.HeaderCell>
          {
            !mobile ? (
              <Table.HeaderCell width={4} />
            ) : null
          }
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {
          filteredProjects.map(({ id, name, code, start, end, status, createdAt }) => {
          const range = start && end
            ? `${moment(Number(start)).format('DD.MM')} - ${moment(Number(end)).format('DD.MM')}`
            : null;

            return (
              <Table.Row key={id}>
                {
                  !mobile ? (
                    <Table.Cell
                      textAlign={mobile ? 'center' : 'left'}
                      style={{ paddingRight: 0 }}
                    >
                      <ProjectStatusLabel status={status} />
                    </Table.Cell>
                  ) : null
                }
                <Table.Cell>
                  <Link to={`/projects/${id}`}>{ name }</Link><br/>
                  <HorizontalList
                    items={[
                      mobile ? <ProjectStatusLabel status={status} /> : null,
                      code,
                      range
                    ]}
                  />
                </Table.Cell>
                {
                  !mobile ? (
                    <Table.Cell>
                      <Icon name='clock outline' /> { moment(Number(createdAt)).calendar() }
                    </Table.Cell>
                  ) : null
                }
              </Table.Row>
            );
          })
        }
      </Table.Body>
    </Table>
  );
};

export const ActiveProjects = () => AllProjects({ filter: (project) => project.status === 'active' });

export const PendingProjects = () => AllProjects({ filter: (project) => project.status === 'pending' });

export const CompletedProjects = () => AllProjects({ filter: (project) => project.status === 'completed' });
