import gql from 'graphql-tag';

const UPDATE_PROJECT = gql`
  mutation (
    $entity: ProjectUpdateInput!
  ) {
    updateProject (
      entity: $entity,
    )
  }
`;

export default UPDATE_PROJECT;
