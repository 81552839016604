import gql from 'graphql-tag';

const SIGN_IN = gql`
  mutation signIn (
    $data: SignInInput!
  ){
    signIn (
      data: $data
    ) {
      organizations {
        id
        name
        joinedAt
      }
      token
    }
  }
`;

export default SIGN_IN;
