import gql from 'graphql-tag';

export const GET_WORK = gql`
  query getWork (
    $workId: Float!
  ) {
    work (
      workId: $workId
    ) {
      id
      name
      code
      unit
      requireUnitInput
      archived

      createdAt
      updatedAt

      projects {
        id
        name
      }
    }
  }
`;
