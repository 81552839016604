import React from 'react';
import { Label } from 'semantic-ui-react';

export const ProjectStatusLabel = ({ status }) => {
  const color = {
    pending: 'grey',
    active: 'olive',
    completed: 'teal',
  };

  const text = {
    pending: 'Pending',
    active: 'Active',
    completed: 'Completed',
  };

  const icon = {
    pending: 'clock outline',
    active: 'circle',
    completed: 'check circle',
  };

  return (
    <Label
      basic
      horizontal
      size='small'
      style={{ marginRight: 0 }}
      color={color[status]}
      icon={icon[status]}
      content={text[status]}
    />
  );
};

