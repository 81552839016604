import gql from 'graphql-tag';

const CREATE_ACCOUNT = gql`
  mutation (
    $entity: AccountCreateInput!
  ) {
    createAccount (
      entity: $entity,
    )
  }
`;

export default CREATE_ACCOUNT;
