import React from 'react';
import { useHistory } from 'react-router';

import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { onError } from "apollo-link-error";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { RetryLink } from 'apollo-link-retry'

import { ApolloLink, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { createHttpLink } from 'apollo-link-http';
import { getMainDefinition } from 'apollo-utilities';

const NODE_ENV = process.env.NODE_ENV;
const API_URL_STAGING = process.env.REACT_APP_API_URL_STAGING || 'localhost:8080';
const API_URL_PRODUCTION = process.env.REACT_APP_API_URL_PRODUCTION || 'localhost:8080';
const HTTP_URL = NODE_ENV === 'development' ? `http://${API_URL_STAGING}/graphql` : `https://${API_URL_PRODUCTION}/graphql`;
const WS_URL = NODE_ENV === 'development' ? `ws://${API_URL_STAGING}/graphql` : `wss://${API_URL_PRODUCTION}/graphql`;

const ACCESS_DENIED_ERROR_MESSAGES = [
  'Access denied! You need to be authorized to perform this action!',
  `Access denied! You don't have permission for this action!`,
];

export const ApolloConfiguration = ({ children }) => {
  const history = useHistory();

  const httpLink = createHttpLink({ uri: HTTP_URL });

  const wsLink = new WebSocketLink({
    uri: WS_URL,
    options: {
      reconnect: true,
      connectionParams: {
        authToken: localStorage.getItem('token'),
      },
    },
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        token: token ? token : '',
      }
    }
  });

  const unauthorisedHandlerLink = onError((errors) => {
    const { graphQLErrors } = errors;

    if (!graphQLErrors) {
      return false;
    }

    if (NODE_ENV === 'development') {
      console.log('ERRORS', errors);
    }

    graphQLErrors.forEach((error) => {
      if (ACCESS_DENIED_ERROR_MESSAGES.includes(error.message)) {
        localStorage.removeItem('token');
        history.push('/signin');
      }
    });
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 50,
    },
  });

  // Combine links together
  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);

      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    ApolloLink.from([ retryLink, unauthorisedHandlerLink, authLink, httpLink ]),
  )

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      { children }
    </ApolloProvider>
  );
}
