import gql from 'graphql-tag';

export const SET_ENTRIES_APPROVED = gql`
  mutation (
    $data: EntriesApproveInput!
  ) {
    setEntriesApproved (
      data: $data,
    )
  }
`;
