import gql from 'graphql-tag';

const ACCEPT_INVITATION_WITH_PASSWORD = gql`
  mutation acceptInvitationWithPassword (
    $data: AcceptInvitationWithPasswordInput!
  ) {
    acceptInvitationWithPassword (
      data: $data
    )
  }
`;

export default ACCEPT_INVITATION_WITH_PASSWORD;
