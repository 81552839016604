import React, { useContext, useState, useEffect } from 'react';
import { Transition, Menu, Sticky, Dimmer, Loader, Icon } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';

import { AppContext } from '../../../contexts/AppContext';
import { SET_ENTRIES_APPROVED } from '../../../graphql/mutations/setEntriesApproved';
import { parseErrors } from '../../../libs/errors';
import { EntriesApprovalContext } from '../contexts/EntriesApprovalContext';

export const ApprovalDetails = () => {
  const { mobile, setError } = useContext(AppContext);
  const { approvalDetailsRef, selectedRows, setSelectedRows } = useContext(EntriesApprovalContext);
  const [mutationLoading, setMutationLoading] = useState(false);
  const [visible, setVisible] = useState(true);

  const [setApprovedMutation] = useMutation(SET_ENTRIES_APPROVED, {
    variables: {
      data: {
        entries: selectedRows,
      },
    },
    refetchQueries: ['getEntries'],
  });

  async function setApproved() {
    try {
      setMutationLoading(true);

      await setApprovedMutation();
      setSelectedRows([]);

      setMutationLoading(false);
    } catch (e) {
      setMutationLoading(false);

      const errors = parseErrors(e);
      setError(errors);
    }
  }

  useEffect(() => {
    if (!selectedRows.length) {
      setVisible(true);
    }

    const timer = selectedRows.length === 0 ? setTimeout(() => { 
      setVisible(!visible);
    }, 2000) : null;

    return () => clearTimeout(timer);
  }, [visible, selectedRows.length]);

  function unselectAllRows() {
    setSelectedRows([]);
  }

  if (mobile) {
    return null;
  }

  const title = selectedRows.length > 0
    ? `Selected ${selectedRows.length} entries`
    : (
        <>
          <Transition
            animation='pulse'
            duration={300}
            visible={visible}
          >
            <Icon name='hand pointer' />
          </Transition>
          Select entries for approval by clicking the row or dragging cursor along multiple rows.
        </>
      );

  return (
    <Sticky
      context={approvalDetailsRef}
      offset={74}
    >
      <Menu borderless inverted>
        <Menu.Item>{ title }</Menu.Item>
        {
          selectedRows.length > 0 && (
            <Menu.Menu position='right'>
              <Menu.Item
                style={{ backgroundColor: 'rgba(255,255,255,.1)' }}
              onClick={setApproved}
            >
              <Dimmer active={mutationLoading}>
                <Loader />
              </Dimmer>
              <Icon name='check' color='olive' />
              Approve
            </Menu.Item>

            <Menu.Item
              name='help'
              >
                <Icon
                  link
                  name='close'
                  style={{margin: 0}}
                  onClick={unselectAllRows}
                />
              </Menu.Item>
            </Menu.Menu>
          )
        }
      </Menu>
    </Sticky>
  );
}
