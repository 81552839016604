import React, { useState } from 'react';
import { Message, Button, Form, Modal, Divider } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { useMutation, useQuery } from '@apollo/react-hooks';

import CREATE_PROJECT from '../graphql/mutations/createProject';
import GET_DEVICES from '../graphql/queries/getDevices';
import GET_WORKERS from '../graphql/queries/getWorkers';
import GET_WORKS from '../graphql/queries/getWorks';
import { parseErrors } from '../libs/errors';

function mapEntityToDropdownOptions(entities = []) {
  return entities.map(entity => ({
    key: entity.id,
    text: entity.name,
    value: entity.id,
  }));
}

const CreateProjectModal = ({ isOpen, setOpen }) => {
  const close = () => setOpen(false);

  const [formData, setFormData] = useState({
    name: '',
    code: '',
    start: '',
    end: '',
    budget: '',
    works: [],
    workers: [],
    devices: [],
  });

  const [mutationLoading, setMutationLoading] = useState(false);
  const [mutationErrors, setMutationErrors] = useState(null);

  const { data: worksData = {}, loading: worksLoading } = useQuery(GET_WORKS);
  const { data: workersData = {}, loading: workersLoading } = useQuery(GET_WORKERS);
  const { data: devicesData = {}, loading: devicesLoading } = useQuery(GET_DEVICES);

  const loading = worksLoading || workersLoading || devicesLoading;

  const [createProjectMutation] = useMutation(CREATE_PROJECT, {
    variables: {
      entity: {
        ...formData,
        budget: Number(formData.budget),
      },
    },
    refetchQueries: ['getProjects'],
  });

  async function createProject() {
    try {
      setMutationLoading(true);
      await createProjectMutation();

      close();
    } catch (e) {
      setMutationLoading(false);

      const errors = parseErrors(e);
      setMutationErrors(errors);
    }
  }

  function renderWorkerLabel({ value, text }) {
    const hasPhoneNumber = !!workersData.workers.find((x) => x.id === value).phoneNumber;

    return {
      content: text,
      icon: hasPhoneNumber && 'mobile alternate',
    };
  }

  function handleChange(e, { name, value }) {
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const errorMessage = mutationErrors && mutationErrors.general ? (
    <Message negative>
      <Message.Header>Error has occured</Message.Header>
      <p>{ mutationErrors.general }</p>
    </Message>
  ) : null;

  return (
    <Modal
      size='tiny'
      dimmer={'blurring'}
      centered={false}
      open={isOpen}
      onClose={close}
    >
      <Modal.Header>Add new project</Modal.Header>
      <Modal.Content>
        { errorMessage }
        <Form
          size='small'
          autoComplete='off'
          id='modal-form'
        >
          <Form.Input
            fluid
            required
            name='name'
            label='Name'
            value={formData.name}
            error={mutationErrors ? mutationErrors.name : null}
            onChange={handleChange}
          />

          <Form.Input
            fluid
            name='code'
            label='Code'
            value={formData.code}
            error={mutationErrors ? mutationErrors.code : null}
            onChange={handleChange}
          />

          <Divider section hidden />

          <Form.Group>
            <DateInput
              width={6}
              name='start'
              label='Start date'
              value={formData.start}
              animation='none'
              dateFormat='DD.MM.YYYY'
              closable={true}
              popupPosition='bottom right'
              preserveViewMode={false}
              error={mutationErrors ? mutationErrors.start : null}
              onChange={handleChange}
            />

            <DateInput
              width={6}
              name='end'
              label='End date'
              value={formData.end}
              animation='none'
              dateFormat='DD.MM.YYYY'
              minDate={formData.start}
              closable={true}
              popupPosition='bottom right'
              preserveViewMode={false}
              error={mutationErrors ? mutationErrors.end : null}
              onChange={handleChange}
            />

            <Form.Input
              width={4}
              label='Time budget, hrs'
              name='budget'
              type='number'
              min={0}
              value={formData.budget}
              error={mutationErrors ? mutationErrors.budget : null}
              onChange={handleChange}
            />
          </Form.Group>

          <Divider hidden />

          <Form.Dropdown
            fluid
            clearable
            multiple
            upward
            search
            selection
            name='works'
            label='Assigned Works'
            placeholder='Choose works'
            loading={worksLoading}
            value={formData.works}
            options={mapEntityToDropdownOptions(worksData.works)}
            error={mutationErrors ? mutationErrors.works : null}
            onChange={handleChange}
          />

          <Form.Dropdown
            fluid
            clearable
            multiple
            upward
            search
            selection
            name='workers'
            label='Assigned Workers'
            placeholder='Choose workers'
            loading={workersLoading}
            value={formData.workers}
            options={mapEntityToDropdownOptions(workersData.workers)}
            renderLabel={(label) => renderWorkerLabel(label)}
            error={mutationErrors ? mutationErrors.workers : null}
            onChange={handleChange}
          />

          <Form.Dropdown
            fluid
            clearable
            multiple
            upward
            search
            selection
            name='devices'
            label='Assigned Devices'
            placeholder='Choose devices'
            loading={devicesLoading}
            value={formData.devices}
            options={mapEntityToDropdownOptions(devicesData.devices)}
            error={mutationErrors ? mutationErrors.devices : null}
            onChange={handleChange}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content='Cancel'
          onClick={close}
        />
        <Button
          form='modal-form'
          color='teal'
          icon='plus'
          content='Create'
          loading={mutationLoading || loading}
          disabled={mutationLoading || loading}
          onClick={createProject}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default CreateProjectModal;
