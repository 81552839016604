import gql from 'graphql-tag';

const UDPDATE_WORKER = gql`
  mutation (
    $entity: WorkerUpdateInput!
  ) {
    updateWorker (
      entity: $entity,
    )
  }
`;

export default UDPDATE_WORKER;
