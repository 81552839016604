import gql from 'graphql-tag';

export const GET_WORKER = gql`
  query getWorker (
    $workerId: Float!
  ) {
    worker (
      workerId: $workerId
    ) {
      id
      name
      code
      phoneNumber
      archived

      createdAt
      updatedAt

      projects {
        id
        name
      }
    }
  }
`;
