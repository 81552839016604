import gql from 'graphql-tag';

const GET_ENTRIES = gql`
  query getEntries (
    $filters: EntryFilters
    $orderBy: EntryOrder
  ) {
    entries (
      filters: $filters
      orderBy: $orderBy
    ) {
      id
      start
      end
      status
      timezone
      completedUnits

      work {
        id
        name
        unit
      }

      worker {
        id
        name
      }

      device {
        id
        name
      }

      project {
        id
        name
      }
    }
  }
`;

export default GET_ENTRIES;
