import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';

import { calculatePrediction } from '../utils/prediction';
import { calculateProgressSeriesData } from '../utils/progressSeriesData';
import {
  sumEntriesByWork,
  sortEntries,
  getExtemumEntries,
  getMinX,
  getMaxX,
  getMaxY,
  getTotalSpentTime,
  getTotalSpentTimeFirstDay,
} from '../utils/common';
import GET_PROJECT from '../../../graphql/queries/getProject';
import { useManipulators } from '../../../hooks/useManipulators';
import GET_ENTRIES from '../../../graphql/queries/getEntries';
import { ENTRY_CREATED_IN_PROJECT } from '../../../graphql/subscriptions/entryCreatedInProject';

export const ProjectContext = React.createContext();

export const ProjectContextProvider = withRouter(({ children, match }) => {
  const { projectId } = match.params;

  const [chartReady, setChartReady] = useState(null);
  const [budget, setBudget] = useState(null);
  const [minX, setMinX] = useState(null);
  const [maxX, setMaxX] = useState(null);
  const [maxY, setMaxY] = useState(null);
  const [progressSeriesData, setProgressSeriesData] = useState([]);
  const [totalSpentTime, setTotalSpentTime] = useState(null);
  const [totalSpentTimeByWork, setTotalSpentTimeByWork] = useState(null);
  const [predictedValue, setPredictedValue] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const { filters, orderBy } = useManipulators({
    filters: [
      {
        name: 'project',
        type: 'select',
        default: Number(projectId),
      },
    ],
    orderBy: {
      options: [
        { text: 'Start time', value: 'start' },
      ],
    },
  });

  const { data: projectData = {}, loading: projectLoading } = useQuery(GET_PROJECT, {
    variables: {
      projectId: Number(projectId),
    },
  });

  const { data: entriesData = {}, loading: entriesLoading, subscribeToMore } = useQuery(GET_ENTRIES, {
    fetchPolicy: 'cache-and-network',
    variables: { filters, orderBy },
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: ENTRY_CREATED_IN_PROJECT,
      variables: { projectId: Number(projectId) },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const createdEntries = subscriptionData.data.entryCreatedInProject;
        const newEntries = createdEntries.filter((x) => prev.entries.findIndex((y) => y.id === x.id) === -1);

        return {
          entries: [
            ...newEntries.reverse(),
            ...prev.entries,
          ],
        };
      },
    });

    return () => unsubscribe();
  }, [subscribeToMore]);

  useEffect(() => {
    const loading = entriesLoading || projectLoading;

    if (!loading && projectData.project && entriesData.entries && entriesData.entries.length) {
      const entries = sortEntries([...entriesData.entries]);
      const extemumEntries = getExtemumEntries(entries);

      const minXValue = getMinX(extemumEntries.earliestEntry);
      const maxXValue = getMaxX(projectData.project.end, extemumEntries.latestEntry);

      setMinX(minXValue);
      setMaxX(maxXValue);

      const seriesData = calculateProgressSeriesData(entries, minXValue);
      const spentTime = getTotalSpentTime(seriesData);
      const spentTimeFirstDay = getTotalSpentTimeFirstDay(seriesData)
      const prediction = calculatePrediction(spentTime, spentTimeFirstDay, minXValue, maxXValue)

      setProgressSeriesData(seriesData);
      setTotalSpentTime(spentTime);
      setPredictedValue(prediction);

      const projectBudget = Number(projectData.project.budget)
      setBudget(projectBudget);
      setMaxY(getMaxY(prediction, projectBudget));

      const summedEntriesByWork = sumEntriesByWork(entries);
      setTotalSpentTimeByWork(summedEntriesByWork);

      setChartReady(true);
    }
  }, [projectData, entriesData, entriesLoading, projectLoading]);

  return (
    <ProjectContext.Provider
      value={{
        entriesData,
        entriesLoading,

        projectData,
        projectLoading,

        chartReady,
        budget,
        totalSpentTime,
        totalSpentTimeByWork,
        progressSeriesData,
        predictedValue,

        boundaries: {
          minX,
          maxX,
          maxY,
        },

        selectedRows,
        setSelectedRows,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
});
