import gql from 'graphql-tag';

const CREATE_DEVICE = gql`
  mutation (
    $entity: DeviceCreateInput!
  ) {
    createDevice (
      entity: $entity,
    )
  }
`;

export default CREATE_DEVICE;
