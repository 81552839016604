// THIS CODE IS SHIT
// I APOLOGIZE TO WHOEVER LAYS THEIR EYES UPON IT

import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Menu, Sidebar, Icon, } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';

import { AppContext } from '../contexts/AppContext';
import { LeftMenuContext } from '../contexts/LeftMenuContext';

const ExpandButton = () => {
  const { leftMenuOpen, closeLeftMenu, openLeftMenu } = useContext(LeftMenuContext);

  return (
    <Menu.Item
      icon={true}
      style={{
        ...styles.collapsedMenuItem,
        ...styles.expandButton,
      }}
      onClick={() => leftMenuOpen ? closeLeftMenu() : openLeftMenu() }
    >
      <Icon
        style={styles.collapsedMenuIcon}
        name={ leftMenuOpen ? 'angle double left' : 'angle double right' }
      />
    </Menu.Item>
  );
}

const DropdownMenu = withRouter(({ location, items }) => {
  const dropdownItems = items.map((item) => {
    const { url, name, icon } = item;
    const active = isActive(location, url);

    return (
      <Dropdown.Item
        key={'dropdown_' + name}
        as={Link}
        to={url}
        icon={icon}
        content={name}
        active={active}
      />
    );
  });

  return (
    <Dropdown.Menu>
      { dropdownItems }
    </Dropdown.Menu>
  );
});

const isActive = (location, url) => location.pathname.includes(url);

const LeftMenuItem = withRouter(({ location, history, item, sub }) => {
  const { name, icon, url, items } = item;
  const { mobile } = useContext(AppContext);
  const { leftMenuOpen, closeLeftMenu } = useContext(LeftMenuContext);
  const anyActiveChildren = items ? items.map(x => x.url).some(x => isActive(location, x)) : false;
  const active = isActive(location, url) || anyActiveChildren;

  function handleDropdownClick(event, url) {
    // This function is called even if child is clicked,
    // therefore this condition is necessary
    if(event.target === event.currentTarget) {
      history.push(url);
    }
  }

  if (items && active && (leftMenuOpen || mobile)) {
    return (
      <React.Fragment>
        <Menu.Item
          as={Link}
          to={url}
          active={active}
          style={{ ...styles.menuItem, ...styles.subMenu }}
          onClick={() => mobile ? closeLeftMenu() : null}
        >
          <Icon
            name={icon}
            style={styles.expandedMenuIcon}
          />
          {name}
        </Menu.Item>
        <Menu
          fluid
          vertical
          borderless
          secondary
          icon
          style={{ ...styles.menu, ...styles.subMenu }}
        >
          { items.map((item) => <LeftMenuItem key={'sub_' + item.name} item={item} sub />) }
        </Menu>
      </React.Fragment>
    );
  }

  if (leftMenuOpen || mobile) {
    return (
      <React.Fragment>
        {
          items && !mobile ? (
            <Dropdown
              simple
              item
              icon={false}
              onClick={(e) => handleDropdownClick(e, url)}
              style={{
                ...(sub ? styles.subMenuItem : styles.menuItem),
                ...(active && !sub && styles.activeMenuItem),
              }}
            >
              <React.Fragment>
                <Icon
                  name={icon}
                  style={styles.expandedMenuIcon}
                  onClick={(e) => handleDropdownClick(e, url)}
                />
                { name }
                <DropdownMenu items={items} />
              </React.Fragment>
            </Dropdown>
          ) : (
            <Menu.Item
              as={Link}
              to={url}
              active={active}
              style={{
                ...(sub ? styles.subMenuItem : styles.menuItem),
                ...(active && !sub && styles.activeMenuItem),
              }}
              onClick={() => mobile ? closeLeftMenu() : null}
            >
              <Icon
                name={icon}
                style={styles.expandedMenuIcon}
              />
              {name}
            </Menu.Item>
          )
        }
      </React.Fragment>
    );
  }

  return items ? (
    <Dropdown
      simple
      item
      onClick={(e) => handleDropdownClick(e, url)}
      icon={false}
      style={{
        ...styles.menuItem,
        ...(active && styles.activeDropdownItem),
      }}
    >
      <React.Fragment>
        <Icon
          name={icon}
          style={styles.collapsedDropdownIcon}
          onClick={(e) => handleDropdownClick(e, url)}
        />
        <DropdownMenu items={items} />
      </React.Fragment>
    </Dropdown>
  ) : (
    <Menu.Item
      as={Link}
      to={url}
      title={name}
      active={active}
      style={styles.collapsedMenuItem}
    >
      <Icon
        name={icon}
        style={styles.collapsedMenuIcon}
      />
    </Menu.Item>
  );
});

export const LeftMenu = withRouter(({ location }) => {
  const { mobile } = useContext(AppContext);
  const { leftMenuOpen, closeLeftMenu } = useContext(LeftMenuContext);
  const [url, setUrl] = useState(null);

  // This effect is forcefully closes left menu, when url in browser has changed.
  // Relevant only for mobile users.
  useEffect(() => {
    if (mobile && location.pathname !== url) {
      closeLeftMenu();
    }

    setUrl(location.pathname);
  }, [mobile, url, setUrl, location, closeLeftMenu]);

  const items = [{
    name: 'Projects',
    icon: 'clipboard outline',
    url: '/projects',
  }, {
    name: 'Team',
    icon: 'user outline',
    url: '/workers',
    items: [{
      name: 'Workers',
      url: '/workers',
      icon: 'id badge outline',
    }, {
      name: 'Accounts',
      url: '/accounts',
      icon: 'user circle outline',
    }],
  }, {
    name: 'Works',
    icon: 'tasks',
    url: '/works',
  }, {
    name: 'Devices',
    icon: 'mobile alternate',
    url: '/devices',
  }, {
    name: 'Reporting',
    icon: 'file excel outline',
    url: '/reporting',
  }].map((item) => <LeftMenuItem key={item.name} item={item} />);

  return (
    <Sidebar
      style={{
        ...styles.sidebar,
        ...(leftMenuOpen ? styles.sidebarOpen : styles.sidebarClosed),
        ...(mobile && styles.sidebarOpen)
      }}
      visible={mobile ? leftMenuOpen : true}
      animation='push'
    >
      <Menu
        fluid
        vertical
        borderless
        icon
        style={styles.menu}
      >
        { items }
        <ExpandButton />
      </Menu>
    </Sidebar>
  );
});

const styles = {
  sidebar: {
    boxShadow: 'none',
    backgroundColor: '#f8f8f8',
    borderRight: '1px solid rgba(34,36,38,.15)',
    top: '60px',
  },
  sidebarOpen: {
    width: '200px',
  },
  sidebarClosed: {
    width: '60px',
  },

  menu: {
    border: 0,
    borderRadius: 0,
    boxShadow: 'none',
    margin: 0,
    backgroundColor: '#f8f8f8',
  },
  subMenu: {
    backgroundColor: '#f0f0f0',
    borderLeft: '4px solid #aaaaaa',
  },
  menuItem: {
    padding: '23px 21px',
    textAlign: 'left',
    margin: 0,
    color: '#1b1c1d',
    borderRadius: 0,
  },
  subMenuItem: {
    padding: '16px 21px',
    textAlign: 'left',
    margin: 0,
    color: '#1b1c1d',
    fontSize: 'inherit',
  },
  activeMenuItem: {
    borderLeft: '4px solid #aaaaaa',
  },
  expandedMenuIcon : {
    float: 'left',
    marginRight: '1rem',
  },
  collapsedMenuItem: {
    display: 'flex',
    height: '60px',
  },
  collapsedMenuIcon: {
    alignSelf: 'center',
  },


  activeDropdownItem: {
    backgroundColor: 'rgba(0,0,0,.05)',
  },
  activeDropdownMenuItem: {
    fontWeight: 'bold',
  },
  collapsedDropdownIcon: {
    float: 'left',
    margin: 0,
  },

  expandButton: {
    position: 'fixed',
    width: '100%',
    bottom: '60px',
  },
};
