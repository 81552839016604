import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { Disclaimer } from '../../../components/Disclaimer';

export const AssignedProjectsTable = ({ work }) => {
  const tableRows = work.projects.map(({ id, name }) => (
    <Table.Row key={id}>
      <Table.Cell>
        <Link to={`/projects/${id}`}>{ name }</Link>
      </Table.Cell>
    </Table.Row>
  ));

  if (!work.projects.length) {
    return (
      <Table unstackable basic>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan={2}><Icon name='clipboard outline' /> Assigned to projects</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={2}>
              <Disclaimer
                icon='exclamation triangle'
                content='Work is not assigned to any projects'
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }

  return (
    <Table unstackable basic>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell colSpan='2'><Icon name='clipboard outline' /> Assigned to projects</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{ tableRows }</Table.Body>
    </Table>
  );
};
