import gql from 'graphql-tag';

export const GET_QUOTA = gql`
  query getQuota (
    $data: QuotaRequestInput!
  ) {
    quota (
      data: $data
    ) {
      quota
      used
    }
  }
`;
