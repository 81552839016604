import React from 'react';
import { Grid } from 'semantic-ui-react';

import { WorkInfoTable } from './WorkInfoTable';
import { AssignedProjectsTable } from './AssignedProjectsTable';

export const OverviewTab = ({ work }) => {
  return (
    <Grid
      doubling
      columns={2}
      padded='vertically'
    >
      <Grid.Column>
        <WorkInfoTable work={work} />
      </Grid.Column>

      <Grid.Column>
        <AssignedProjectsTable work={work} />
      </Grid.Column>
    </Grid>
  );
};
