import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { Responsive } from 'semantic-ui-react';
import { hotjar } from 'react-hotjar';

import { ApolloConfiguration } from './components/ApolloConfiguration';
import { AppContextProvider } from './contexts/AppContext';
import { RightMenuContextProvider } from './contexts/RightMenuContext';
import { LeftMenuContextProvider } from './contexts/LeftMenuContext';
import App from './App';

import 'semantic-ui-css/semantic.min.css';
import './styles/index.css';

hotjar.initialize('1650121', '6');

const Root = () => (
  <AppContextProvider>
    <LeftMenuContextProvider>
      <RightMenuContextProvider>
        <BrowserRouter>
          <ApolloConfiguration>
            <React.Fragment>
              <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <App />
              </Responsive>

              <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                <App mobile />
              </Responsive>
            </React.Fragment>
          </ApolloConfiguration>
        </BrowserRouter>
      </RightMenuContextProvider>
    </LeftMenuContextProvider>
  </AppContextProvider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
