import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // TODO: move all token operations to separate util module
  const token = localStorage.getItem('token');

  return (
    <Route
      {...rest}
      render={(props) => (
        token !== null ? (
          <Component {...props} />
        ) : (
          //TODO: when authorised, redirect back to requested page
          <Redirect to='/signin' />
        )
      )}
    />
  );
};

export default PrivateRoute;  
