import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import moment from 'moment';

export const WorkerInfoTable = ({ worker }) => {
  const tableRows = [{
    key: 'Phone number',
    value: worker.phoneNumber || '-',
  }, {
    key: 'Created',
    value: moment(Number(worker.createdAt)).calendar(),
  }, {
    key: 'Updated',
    value: worker.updatedAt ? moment(Number(worker.updatedAt)).calendar() : '-',
  }, ].map(({ key, value }) => (
    <Table.Row key={key}>
      <Table.Cell>{ key }</Table.Cell>
      <Table.Cell textAlign='right'>{ value }</Table.Cell>
    </Table.Row>
  ));

  return (
    <Table unstackable basic>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell colSpan='2'><Icon name='id badge outline' /> Worker</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{ tableRows }</Table.Body>
    </Table>
  );
};
