import React from 'react';
import { List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export const DevicesTable = ({ devices }) => {
  const devicesTableRows = devices
    .map(({ id, name }) => (
      <List.Item key={name}>
        <List.Content style={styles.listContent}>
          <List.Header
            as={Link}
            to={`/devices/${id}`}
            content={name}
          />
        </List.Content>
      </List.Item>
    ));

  if (!devices.length) {
    return (
      <p style={styles.noAssignedItems}>No devices assigned yet</p>
    );
  }

  return (
    <List>
      { devicesTableRows }
    </List>
  );
}

const styles = {
  listContent: {
    padding: 0,
  },
  noAssignedItems: {
    textAlign: 'center',
    padding: '20px 0',
  },
};
