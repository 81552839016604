import React from 'react';
import { useLocation } from 'react-router';

import CommonLayout from '../../layouts/CommonLayout';
import { GeneralReporting } from './components/GeneralReporting';

const Reports = () => {
  const location = useLocation();

  return (
    <CommonLayout
      header={{
        text: 'Reporting Tool',
      }}

      surface={
        <GeneralReporting data={location.data} />
      }
    />
  );
};

export default Reports;
